<template>
  <div :class="$style.container">
    <no-data-chart v-if="isNoData" />
    <div v-else :id="id" :class="$style.chartBox" :style="{ height }"></div>
  </div>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import * as echarts from 'echarts';
import resize from '@/utils/resize';
import dayjs from 'dayjs';
// import { createModal } from '../message/modal';
// import SafeList from '../common/safe-list';
const lineOptions = (data, nowSize) => {
  const category = data.map(e => dayjs(e.time).format('MM-DD'));
  const seriesData = data.map(e => e.safeIndex);
  const min = Math.min(...seriesData);
  return {
    tooltip: {
      trigger: 'axis',
      backgroundColor: 'rgba(3,29,63,0.6)', // 通过设置rgba调节背景颜色与透明度
      color: '#fff',
      borderWidth: '1',
      borderColor: '#0DC8FE',
      padding: nowSize(5),
      textStyle: {
        color: '#fff',
        fontSize: nowSize(14),
      },
      formatter: function(params) {
        let str = `${params[0].name}： ${params[0].value}%`;
        return str;
      },
      extraCssText: 'border-radius: 0; padding: 10px;',
      axisPointer: {
        type: 'line',
        z: 1,
        lineStyle: {
          color: '#fff',
          opacity: 0.2,
        },
      },
    },
    grid: {
      x: 50,
      y: 30,
      x2: 20,
      y2: 40,
    },

    toolbox: {
      feature: {
        saveAsImage: {
          show: false,
        },
      },
    },
    xAxis: {
      type: 'category',
      boundaryGap: true,
      triggerEvent: true,
      data: category,
      // 隐藏刻度线
      axisTick: { show: false },
      axisLine: {
        show: false,
        lineStyle: {
          show: false,
          color: '#fff',
        },
      },
      axisLabel: {
        fontSize: nowSize(12),
      },
    },
    yAxis: {
      max: 100,
      min: min,

      splitLine: {
        show: true,
        lineStyle: {
          show: true,
          color: ['#1c1e32'],
        },
      },
      type: 'value',
      // 隐藏刻度线
      axisTick: { show: false },
      axisLine: {
        show: false,
        lineStyle: {
          show: false,
          color: '#fff',
        },
      },
      axisLabel: {
        fontSize: nowSize(12),
        formatter: '{a|{value}%}',
        width: 20,
        rich: {
          a: {
            align: 'left',
            fontSize: nowSize(12),
          },
        },
      },
    },
    series: [
      {
        name: 'test',
        type: 'line',
        stack: '总量',
        smooth: true,
        symbol: 'none',
        data: seriesData,
        itemStyle: {
          normal: {
            // 颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: '#02051B', // 0% 处的颜色
              },
              {
                offset: 0.5,
                color: '#06CAF7', // 100% 处的颜色
              },
              {
                offset: 1,
                color: '#fff', // 100% 处的颜色
              },
            ]), // 背景渐变色
            lineStyle: {
              // 系列级个性化折线样式
              width: 3,
              type: 'solid',
              color: '#4fd6d2',
            },
          },
          emphasis: {
            color: '#4fd6d2',
            lineStyle: {
              // 系列级个性化折线样式
              width: 2,
              type: 'dotted',
              color: '#1dc2f1', // 折线的颜色
            },
          },
        }, // 线条样式
        areaStyle: {
          normal: {
            // 颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: 'rgba(80,141,255,0.39)',
              },
              {
                offset: 0.34,
                color: 'rgba(56,155,255,0.25)',
              },
              {
                offset: 1,
                color: 'rgba(38,197,254,0.00)',
              },
            ]),
          },
        }, // 区域颜
      },
      {
        name: 'tes',
        type: 'bar',
        barWidth: 22,
        itemStyle: {
          color: 'transparent',
        },
        barGap: '-100%',
        barCategoryGap: '40%',
        data: category.map(() => 100),
        animation: false,
      },
    ],
  };
};

@Component({
  mixins: [resize],
})
export default class SafeBuild extends Vue {
  @Prop({ type: String, default: 'safeBuild' }) id;
  @Prop({ type: String, default: '100%' }) height;
  @Prop({ type: Array, default: null }) data;
  mounted() {
    if (this.data && this.data.length) this.initChart();
  }
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  }
  get isNoData() {
    if (!this.data || !this.data.length) {
      if (this.chart) {
        this.chart.dispose();
        this.chart = null;
      }
      return true;
    } else {
      this.$nextTick(this.initChart);
      return false;
    }
  }
  initChart() {
    if (!this.chart) {
      this.chart = echarts.init(document.getElementById(this.id));
      this.chart.on('click', e => {
        const index =
          e.componentType === 'series'
            ? e.dataIndex
            : this.chart.getOption().xAxis[0].data.indexOf(e.value);
        this.$emit('showSafeModal', index);
      });
    }
    const options = lineOptions(this.data, this.$size);
    this.chart.setOption(options);
  }
}
</script>

<style lang="less" module>
.container {
  height: 100%;
  position: relative;
}
.noDataBox {
  position: relative;
  width: 100%;
  height: 100%;
  .icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.2rem;
    color: #07d3fd;
  }
}
.chartBox {
  width: 100%;
}
</style>
