<template>
  <header class="screen-header">
    <ul class="patternWrap">
      <li
        class="patternItem"
        :class="pattern === PATTERN_DETAIL ? 'active' : ''"
        @click="handleChangePattern(PATTERN_DETAIL)"
      >
        {{ $t('screen.overview') }}
      </li>
      <li class="patternBr"></li>
      <li
        class="patternItem"
        :class="pattern === PATTERN_MAP ? 'active' : ''"
        @click="handleChangePattern(PATTERN_MAP)"
      >
        {{ $t('screen.map') }}
      </li>
    </ul>
    <div class="screen-header__title">{{ $t('screen.title') }}</div>
    <div class="screen-header__tools">
      <fullScreenBtn
        showText
        class="tools-action pointer"
        @fullscreenChange="handleChangeScreen"
      />
      <span class="tools-action pointer" @click.stop="handleIsShowMessageBox">
        <i
          class="TC tc-icon-bell1"
          :class="{ un_read: +unReadMessageCount }"
        ></i>
      </span>

      <span class="tools-action pointer" @click.stop="handleShowSearchBox">
        <i class="TC tc-icon-sousuo"></i>
      </span>
      <span class="tools-action">{{ dataStr }}</span>
    </div>
  </header>
</template>
<script>
import { Component, Emit, Vue } from 'vue-property-decorator';
import { getDateStr } from '@/utils/common.js';
import createSearchBox from './screen-header/create-search.js';
import createMessageBox from './message/create-message-box.js';
import fullScreenBtn from '@/components/fullscreen-btn';

@Component({
  components: { fullScreenBtn },
})
export default class ScreenHeader extends Vue {
  created() {
    this.PATTERN_DETAIL = 'pattern_detail'; // 概览模式
    this.PATTERN_MAP = 'pattern_map'; // 地图模式
    this.pattern = this.PATTERN_DETAIL;
    this.dateDance();
  }

  pattern = '';
  handleChangePattern(type) {
    if (this.pattern === type) return;
    this.pattern = type;
    this.$emit('change-pattern', this.pattern);
  }

  handleChangeSite(option) {
    this.$store.commit('screen/SET_CURRENT_MATCH_SITE', option.value);
    this.$emit('change-site', option);
  }
  isSearch = false;
  async handleShowSearchBox() {
    this.isSearch = !this.isSearch;
    if (this.isSearch) {
      const result = await createSearchBox();
      if (result) {
        this.isSearch = false;
        result.level && this.handleChangeSite(result);
      }
    }
  }

  get unReadMessageCount() {
    const count =
      this.$store.getters['screen/faultCount'] +
      this.$store.getters['screen/warnCount'] +
      this.$store.getters['screen/infosCount'];

    return `${count}`;
  }
  isMessage = false;
  async handleIsShowMessageBox() {
    this.$store.dispatch('screen/haveReadAll');
    this.isMessage = !this.isMessage;
    if (this.isMessage) {
      const result = await createMessageBox({
        style: {
          right: '1.55rem',
        },
      });
      result && (this.isMessage = false);
    }
  }

  // 时间跳动
  dataStr = '';
  async dateDance() {
    this.dataStr = getDateStr(new Date());
    setTimeout(this.dateDance, 1000);
  }

  /** @name 注册全屏全局通知事件 */
  @Emit('full-screen-change')
  handleChangeScreen() {
    this.$store.commit(
      'screen/SET_FULL_SCRREN_CHANGE',
      !this.$store.state.screen.hasChangeFullScrren,
    );
  }
}
</script>
<style lang="less" scoped>
@fff: #fff;
@bgColor: #02051b;
@fontColor: rgba(5, 211, 255, 1);
@fontcolor2: rgba(5, 209, 253, 1);
@lineColor: rgba(13, 200, 254, 1);
@lineColor5: rgba(13, 200, 254, 0.5);
.screen-header {
  height: 0.8rem;
  border-bottom: 0.01rem solid @lineColor5;
  position: relative;
  padding: 0 0.2rem;
  box-sizing: border-box;
  ::v-deep {
    .el-input__inner {
      background-color: #02051b;
    }
    .el-select .el-input.is-focus .el-input__inner {
      border-color: rgba(5, 211, 255, 0.2);
    }
    .el-input__inner {
      border-color: rgba(5, 211, 255, 0.2);
      border-radius: 0.04rem;
      height: 0.4rem;
      line-height: 0.4rem;
      padding: 0 0.15rem;
      font-size: 0.14rem;
    }
    .el-badge__content {
      border-radius: 0.1rem;
      font-size: 0.12rem;
      height: 0.18rem;
      line-height: 0.18rem;
      padding: 0 0.06rem;
      border: none;
      color: #ffffff;
    }
    .el-badge > sup {
      background-color: #f22020;
    }
  }
  .patternWrap {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0.42rem;
    .patternItem {
      color: rgba(5, 211, 255, 0.5);
      font-size: 0.18rem;
      list-style: none;
      display: inline-block;
      cursor: pointer;
      &.active {
        font-size: 0.2rem;
        color: rgba(5, 211, 255, 1);
        position: relative;
      }
    }
    .patternBr {
      position: relative;
      width: 0.61rem;
      display: inline-block;
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        transform: translate(-50%, -80%);
        left: 50%;
        width: 0.01rem;
        height: 0.22rem;
        background-color: rgba(5, 211, 255, 1);
      }
    }
  }
  .screen-header__title {
    background: url('~@/views/iot/screen/assets/images/title-bg.png') center top
      no-repeat;
    height: 0.7rem;
    line-height: 0.6rem;
    display: block;
    background-size: 100% 100%;
    letter-spacing: 0.08rem;
    padding: 0 1.1rem;
    font-size: 0.32rem;
    font-weight: bold;
    color: @fontColor;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
  }
  .screen-header__tools {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    height: 100%;
    font-size: 0.16rem;
    color: @fontColor;
    .tools-action {
      padding: 0 0.2rem;
      position: relative;
      &:last-child {
        border: none;
      }
      .full,
      .exit-full {
        height: 0.14rem;
        width: 0.14rem;
      }
      .message {
        padding: 0;
        color: #ffffff;
        position: absolute;
        top: -0.1rem;
        left: 50%;
      }
      .TC {
        font-size: 0.2rem;
      }
    }
    .pointer {
      cursor: pointer;
    }
  }
  ::v-deep {
    .el-scrollbar {
      background: none !important;
    }
    .el-scrollbar .el-select-dropdown__item {
      background: none !important;
    }
    .el-icon-arrow-up {
      line-height: 0.1rem;
      color: #18b0d1;
    }
    .el-icon-arrow-up:before {
      color: #18b0d1;
    }
    .el-select-dropdown {
      font-size: 0.12rem;
      background: rgba(2, 5, 27, 1);
      border: 0.01rem solid rgba(5, 211, 255, 0.2);
      .el-select-group__title {
        color: rgba(5, 211, 255, 1);
        border-bottom: 0.01rem solid rgba(5, 200, 244, 0.1);
        padding-left: 0.1rem;
      }
      .el-select-dropdown__item {
        color: #fff !important;
        font-size: 0.14rem;
        padding-left: 0.3rem;
        &:hover {
          color: var(--font-mouse-hover) !important;
        }
      }
      .el-select-group__wrap:not(:last-of-type)::after {
        background: rgba(5, 211, 255, 0.2);
        display: none;
      }
    }
    .el-popper[x-placement^='bottom'] .popper__arrow {
      border-bottom-color: rgba(5, 211, 255, 0.2);
      &::after {
        border-bottom-color: #11254e;
      }
    }
  }
}
.screen-header__search {
  font-size: 0.12rem;
  position: absolute;
  z-index: 20;
  right: 0.8rem;
  top: 0.8rem;
  width: 2.6rem;
  color: #18b0d1 !important;
  border: 0.01rem solid #0d2e45;
  background: rgba(2, 5, 27, 1);
  box-shadow: 0 0 0.18rem 0.02rem rgba(12, 181, 232, 0.4);
  .search-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 0 0.18rem 0.02rem rgba(12, 181, 232, 0.4) inset;
    background-repeat: no-repeat;
    background-size: 0.02rem 0.2rem, 0.2rem 0.02rem;
    padding: 0.05rem 0.14rem;
    line-height: 0.3rem;
    background: linear-gradient(
      90deg,
      rgba(5, 211, 255, 0.2) 0%,
      transparent 98%
    );
  }
  .search-main {
    padding: 0.16rem 0.16rem 0.07rem;
    .search-content {
      .search-input {
        width: 1.7rem;
        color: #18b0d1 !important;
        border: #18b0d1;
      }
      .search-btn {
        display: inline-block;
        font-size: 0.14rem;
        border: 0.01rem solid rgba(5, 211, 255, 0.2);
        color: #18b0d1;
        border-radius: 0.04rem;
        height: 0.4rem;
        line-height: 0.4rem;
        padding: 0 0.08rem;
        margin-left: 0.06rem;
        cursor: pointer;
      }
    }
    .area-lists {
      margin: 0.1rem 0 0 0;
      display: flex;
      flex-wrap: wrap;
      li {
        flex-direction: column;
        margin: 0 0.14rem 0.1rem 0;
        cursor: pointer;
        font-size: 0.12rem;
      }
      .cur {
        color: #fff;
      }
    }
  }
}
.un_read {
  position: relative;
  &::after {
    position: absolute;
    content: '';
    display: block;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
    width: 0.1rem;
    height: 0.1rem;
    box-sizing: border-box;
    border-radius: 50%;
    background-color: #f22020;
  }
}
</style>
<style lang="less">
.iot-select-dropdown {
  .el-scrollbar {
    background: none !important;
  }
  .el-scrollbar .el-select-dropdown__item {
    background: none !important;
  }
  .el-icon-arrow-up {
    line-height: 0.1rem;
    color: #18b0d1;
  }
  .el-icon-arrow-up:before {
    color: #18b0d1;
  }
  &.el-select-dropdown {
    font-size: 0.12rem;
    background-color: rgba(2, 5, 27, 1);
    border: 0.01rem solid rgba(5, 211, 255, 0.2);
    .el-select-group__title {
      color: rgba(5, 211, 255, 1);
      border-bottom: 0.01rem solid rgba(5, 200, 244, 0.1);
      padding-left: 0.1rem;
    }
    .el-select-dropdown__item {
      color: #fff !important;
      font-size: 0.14rem;
      padding-left: 0.3rem;
      &:hover {
        color: var(--font-mouse-hover) !important;
      }
    }
    .el-select-group__wrap:not(:last-of-type)::after {
      background: rgba(5, 211, 255, 0.2);
      display: none;
    }
  }
  &.el-popper[x-placement^='bottom'] .popper__arrow {
    border-bottom-color: rgba(5, 211, 255, 0.2);
    &::after {
      border-bottom-color: #11254e;
    }
  }
}
</style>
