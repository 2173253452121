import animations from 'create-keyframe-animation';

let index = 0;
/**
 * @param {KeyframeOption} option
 */
export default function useAnimation(option) {
  ++index;
  const nameKey = `moveScroll${index}`;
  function useKeyframe() {
    const y = getPos();
    if (y > 0) {
      // noAnimation(option);
      return false;
    }
    const animation = {
      0: {
        'transform': 'translateY(0) translateZ(0)',
        'z-index': '1',
      },
      100: {
        'transform': `translateY(${y}px) translateZ(0)`,
        'z-index': '1',
      },
    };
    const name = option.name || nameKey;
    animations.registerAnimation({
      name,
      animation,
      presets: {
        duration: option.duration || 10000,
        easing: option.easing || 'linear',
        direction: option.direction || 'normal',
        iterations: option.iterations || 'infinite',
      },
    });
    animations.runAnimation(option.el, name, () => {
      // iterations 只做一次
      const sleepTime = option.sleepTime || 2000;
      setTimeout(() => {
        animations.unregisterAnimation(name);
        option.cb && option.cb(option.tabIndex);
      }, sleepTime);
    });
  }

  // eslint-disable-next-line no-unused-vars
  function noAnimation(option) {
    setTimeout(() => {
      option.cb && option.cb(option.tabIndex);
    }, 5000);
  }

  function getPos() {
    let y = 1;
    try {
      const wrapperEl = option.el;
      const boxEl = option.el.parentNode;
      const boxHeight = boxEl.getBoundingClientRect().height || 200;
      const y = Math.floor(
        boxHeight - wrapperEl.getBoundingClientRect().height,
      );
      return y;
    } catch (error) {
      return y;
    }
  }

  return {
    useKeyframe,
    name: nameKey,
    unregisterAnimation: animations.unregisterAnimation,
  };
}
/**
 * @typedef KeyframeOption
 * @property { any } el
 * @property { Number | String } iterations
 * @property { any } boxEl
 * @property { Number } duration
 * @property { String } easing
 * @property { String } iterations
 * @property { String } name
 */
