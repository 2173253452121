<template>
  <div :class="$style.boxWrapper" ref="boxRef">
    <ul v-if="list && list.length" :class="$style['sort-list']" ref="ulRef">
      <li
        :class="[
          $style['sort-item'],
          cellClickListener ? $style['cursor-pointer'] : '',
        ]"
        v-for="(item, index) in matchList"
        :key="options.name + index"
        @click="handleClick(item, index)"
      >
        <div :class="$style.left" v-if="item">
          <span>{{ index + 1 }}.</span>
          <span v-if="textHtmlFor" v-html="item[text]"></span>
          <span v-else>{{ item[text] }}</span>
        </div>
        <div v-if="countHtmlFor && item" v-html="item[count]"></div>
        <div v-if="item && !countHtmlFor">{{ item[count] }}{{ unit }}</div>
      </li>
      <!-- 更多详情 -->
      <li
        :class="[$style['sort-item'], $style.txt]"
        v-if="moreShow"
        @click="$emit('click-more')"
      >
        <slot name="more">更多详情</slot>
      </li>
    </ul>
    <no-data-chart v-if="noData && list.length === 0" />
  </div>
</template>
<script>
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import useAnimation from '@/utils/use-animation';
import { delay } from '@triascloud/utils';

@Component()
export default class UlList extends Vue {
  /** @name 无数据状态 */
  @Prop({ type: Boolean, default: false }) noData;
  @Prop({ type: Boolean, default: false }) textHtmlFor;
  @Prop({ type: Boolean, default: false }) countHtmlFor;
  /** @name 显示的单位 */
  @Prop({ type: String, default: '' }) unit;
  /** @name 显示的文本 */
  @Prop({ type: String, default: 'text' }) text;
  /** @name 显示的统计数 */
  @Prop({ type: String, default: 'count' }) count;

  @Prop({ type: Array, default: () => [] }) list;

  /** @name 是否显示更多详情（根据限制显示的条数） */
  @Prop({ type: Number, default: 0 }) limit;
  get matchList() {
    if (this.limit) {
      return this.list.slice(0, this.limit);
    } else {
      return this.list;
    }
  }
  get moreShow() {
    if (this.limit && this.list.length > this.limit) {
      return true;
    }
    return false;
  }
  /** @name 不要初始化动画 */
  @Prop({ type: Boolean, default: true }) action;
  @Watch('list', { immediate: true })
  async onChange(newList) {
    if (newList.length > 0 && this.action) {
      await this.$nextTick();
      await delay(50);
      this.initAnimation();
    }
  }

  /** @name 数据是否加载成功 */
  @Prop({ type: Boolean, default: false }) loading;
  @Watch('loading', { immediate: true })
  onLoading(loading) {
    if (loading) {
      this.$nextTick(() => {
        this.checkHasData();
      });
    }
  }
  /** @name 父容器的carousel是否需要自动切换 */
  @Prop({ type: Boolean, default: false }) isSwitch;
  checkHasData() {
    if (!this.list.length && this.isSwitch) {
      this.switchDelayReminder();
      return false;
    }
  }
  switchsetTimeOut = null;
  switchDelayReminder(sleep = 5000) {
    this.switchsetTimeOut = setTimeout(() => {
      this.options.cb && this.options.cb(this.options.tabIndex);
    }, sleep);
  }
  clear() {
    if (this.switchsetTimeOut) {
      clearTimeout(this.switchsetTimeOut);
    }
  }
  destoryed() {
    this.clear();
  }

  get lengthForLimit() {
    if (this.moreShow) {
      return this.limit;
    } else {
      return this.list.length;
    }
  }
  /**
   * @name animation的配置
   * 添加延迟操作
   */
  @Prop({ type: Object, default: () => {} }) options;
  initAnimation() {
    let el = this.$refs.ulRef;
    let y = this.getPos(el);
    if (y > 0 && this.options.cb) {
      this.switchDelayReminder();
    } else {
      const resultOptions = Object.assign(
        {
          el,
          duration: this.lengthForLimit * 1000,
          iterations: 1,
        },
        this.options,
      );
      const { useKeyframe, name } = useAnimation(resultOptions);
      this.$emit('animation-name', name);
      useKeyframe();
    }
  }

  getPos(el) {
    let y = 0;
    try {
      const wrapperEl = el;
      const boxEl = el.parentNode;
      const boxHeight = boxEl.getBoundingClientRect().height || 200;
      y = Math.floor(boxHeight - wrapperEl.getBoundingClientRect().height);
      return y;
    } catch (error) {
      return y;
    }
  }

  /** @name 是否监听li的click事件 */
  @Prop({ type: Boolean, default: false }) cellClickListener;
  handleClick(item, index) {
    if (this.cellClickListener) {
      this.$emit('cell-click', item, index);
    }
  }
}
</script>
<style lang="less" module>
.boxWrapper {
  height: 100%;
}
.sort-list {
  .sort-item {
    padding: 0 0.2rem;
    font-size: 0.16rem;
    line-height: 0.3rem;
    display: flex;
    justify-content: space-between;
    &:nth-child(even) {
      background: #05c8f425;
    }
    &.cursor-pointer {
      cursor: pointer;
    }
  }
  .txt {
    cursor: pointer;
    justify-content: center;
  }
}
</style>
