<template>
  <el-carousel
    ref="elRef"
    style="height: 100%;"
    height="100%"
    :autoplay="options.autoplay"
    :loop="options.loop"
    :interval="options.interval"
    arrow="never"
    indicator-position="none"
    @change="handleChange"
  >
    <el-carousel-item ref="firstParentRef">
      <slot name="firstSlot"></slot>
    </el-carousel-item>
    <el-carousel-item ref="secondParentRef">
      <slot name="secondSlot"></slot>
    </el-carousel-item>
  </el-carousel>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component()
export default class MessageCount extends Vue {
  @Prop({ default: function() {}, type: Function }) handleChange;
  @Prop({
    default: () => ({
      loop: true,
      autoplay: true,
      interval: 5000,
    }),
    type: Object,
  })
  options;

  created() {}

  handleChangeActiveItem(index) {
    this.$refs.elRef && this.$refs.elRef.setActiveItem(index);
  }
}
</script>
<style lang="less" module></style>
