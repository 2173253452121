<template>
  <div :id="id" :class="$style.chartBox" :style="{ height, width }" />
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import * as echarts from 'echarts';

const chartOptions = (data, nowSize) => {
  const legendData = data.map(item => item.name);
  return {
    tooltip: {
      trigger: 'item',
      padding: nowSize(5),
      textStyle: {
        fontSize: nowSize(14),
      },
      formatter: function(params) {
        return `楼栋数/百分比: ${params.data.value}/${params.data.percentage}`;
      },
    },
    legend: {
      orient: 'horizontal',
      bottom: '1%',
      data: legendData,
      textStyle: {
        color: '#ffffff',
        fontSize: nowSize(14),
      },
      itemWidth: nowSize(25),
      itemHeight: nowSize(14),
    },
    series: [
      {
        width: nowSize(400),
        height: nowSize(300),
        type: 'pie',
        data: data,
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)',
          },
        },
        label: {
          position: 'inside',
          fontSize: nowSize(14),
          formatter: function(params) {
            return params.data.percentage;
          },
        },
      },
    ],
    color: ['#ffb23b', '#0089f5', '#00cf57'],
  };
};
@Component()
export default class PieChart extends Vue {
  @Prop({ type: String, default: 'pieChart' }) id;
  @Prop({ type: Array, default: () => [] }) list;
  @Prop({ type: String, default: '300px' }) height;
  @Prop({ type: String, default: '100%' }) width;

  chart = null;
  initChart() {
    this.chart = echarts.init(document.getElementById(this.id));
    const options = chartOptions(this.list, this.$size);
    this.chart.setOption(options);
  }

  mounted() {
    this.initChart();
  }
}
</script>
<style lang="less" module>
.chartBox {
}
</style>
