<template>
  <screen
    ref="screenRef"
    @socket-send-message="messageHandle"
    @areaChange="areaChange"
  >
    <screen-header
      slot="header"
      @change-site="changeQuerySiteBySearch"
      @change-pattern="changePattern"
    />
    <template slot="leftOne">
      <div class="moduleTitle">
        {{ $t('screen.province') }}
        <div class="tab-box">
          <span
            class="tab-text"
            :class="regionCountSort === 0 ? 'active' : ''"
            @click="regionTabChange(0)"
            >{{ $t('screen.buildings') }}</span
          >
          <span
            class="tab-text"
            :class="regionCountSort === 1 ? 'active' : ''"
            @click="regionTabChange(1)"
            >{{ $t('screen.faults') }}</span
          >
        </div>
      </div>
      <div class="moduleChart">
        <carousel ref="regionRef" :handleChange="regionTabChange">
          <template v-slot:firstSlot>
            <ul-list
              :unit="$t('screen.ph.bldg')"
              text="site"
              noData
              :list="buildList"
              :options="{ iterations: 'infinite', name: 'buildingMove' }"
            />
          </template>
          <template v-slot:secondSlot>
            <ul-list
              textHtmlFor
              :unit="$t('screen.times')"
              noData
              text="address"
              :list="deviceErrorList"
              :options="{ iterations: 'infinite', name: 'warningMove' }"
            />
          </template>
        </carousel>
      </div>
    </template>
    <template slot="leftTwo">
      <div class="moduleTitle">
        {{ $t('screen.lifted') }}
        <div class="tab-box">
          <span
            class="tab-text"
            :class="promoteCountSort === 0 ? 'active' : ''"
            @click="promoteTabChange(0)"
            >{{ $t('screen.today') }}</span
          >
          <span
            class="tab-text"
            :class="promoteCountSort === 1 ? 'active' : ''"
            @click="promoteTabChange(1)"
            >{{ $t('screen.predicted') }}</span
          >
        </div>
      </div>
      <div class="moduleChart">
        <carousel
          ref="promoteRef"
          :options="promoteCarouselOptions"
          :handleChange="promoteTabChange"
        >
          <template v-slot:firstSlot>
            <ul-list
              ref="todayRef"
              noData
              textHtmlFor
              :options="{
                cb: promoteTabChange,
                tabIndex: 1,
                name: 'todayMove',
              }"
              :limit="LIMIT_PROMOTE"
              :list="promoteListForToday"
              isSwitch
              :loading="promoteLoading"
              @click-more="todayMoreData"
              cellClickListener
              @cell-click="linkProject"
            />
          </template>
          <template v-slot:secondSlot>
            <ul-list
              ref="forecastRef"
              noData
              textHtmlFor
              :options="{
                cb: promoteTabChange,
                tabIndex: 0,
                name: 'forecastMove',
              }"
              :limit="LIMIT_PROMOTE"
              :list="promoteListForForecast"
              :loading="promoteLoading"
              isSwitch
              @click-more="forecastMoreData"
              cellClickListener
              @cell-click="linkProject"
            />
          </template>
        </carousel>
      </div>
    </template>
    <template slot="leftThree">
      <div class="moduleTitle">{{ $t('screen.top5HighRise') }}</div>
      <div class="moduleChart" ref="topRef">
        <high-building :data="buildHeightList" />
      </div>
    </template>
    <template slot="rightOne">
      <!-- 7日安全防护平台状态统计 -->
      <div class="moduleTitle">{{ $t('screen.weeklyStatus') }}</div>
      <div class="moduleChart">
        <seven-build :data="climbListForSevenDays" />
      </div>
    </template>
    <template slot="rightTwo">
      <!-- 7日预警统计 -->
      <div class="moduleTitle">
        {{ messageCountTxt }}
        <div class="tab-box">
          <span
            class="tab-text"
            :class="messageCountSort === 0 ? 'active' : ''"
            @click="messageTabChange(0)"
            >{{ $t('screen.warnings') }}</span
          >
          <span
            class="tab-text"
            :class="messageCountSort === 1 ? 'active' : ''"
            @click="messageTabChange(1)"
            >{{ $t('screen.faults') }}</span
          >
        </div>
      </div>
      <div class="moduleChart">
        <message-count
          ref="messageCountRef"
          :data="alarmMessageListForErrorOrWarn"
          :handleChange="messageTabChange"
        />
      </div>
    </template>
    <template slot="rightThree">
      <div class="moduleTitle">{{ $t('screen.weeklySafeRate') }}</div>
      <div class="moduleChart">
        <safe-build @showSafeModal="showSafeModal" :data="safeIndexList" />
      </div>
    </template>
    <bottom-message
      slot="footer"
      :messageList="messageList"
      :hanldeMessageBox="hanldeMessageBox"
    />
  </screen>
</template>
<script>
import { Component, Vue, Watch } from 'vue-property-decorator';
import dayjs from 'dayjs';
import Screen from '@/components/main-screen/index.vue';
import BottomMessage from './components/message/bottom-message.vue';
import ScreenHeader from './components/header.vue';
import ScreenPop from '@/views/iot/screen/components/message/pop.vue';
import HighBuilding from '@/views/iot/screen/components/charts/high-building.vue';
import SevenBuild from '@/views/iot/screen/components/charts/seven-build.vue';
import SafeBuild from '@/views/iot/screen/components/charts/safe-build.vue';
import MessageCount from '@/views/iot/screen/components/charts/message-count.vue';
import SafeModal from '@/views/iot/screen/components/common/safe-modal';
import createModal from '@/views/iot/screen/components/message/modal.js';
import UlList from './components/ul-list';
import Carousel from './components/carousel';
import { dateFormatForMonthAndDay } from '@/utils';
import Today from './components/building-promote/today';
import Forecast from './components/building-promote/forecast';
import '@/assets/css/reset.css';
import { screenModule } from '@/enum/store.js';

const EVENT_MESSAGE_ERROR = 'triascloud-iot-electric-box_error';
const EVENT_MESSAGE_WARN = 'triascloud-iot-electric-box_warn';
/** @name 楼栋排行榜 */
const SCREEN_BUILDING_RANKING = 'SCREEN_BUILDING_RANKING';
/** @name 楼栋高度排行榜 */
const SCREEN_BUILDING_HEIGHT_RANKING = 'SCREEN_BUILDING_HEIGHT_RANKING';
/** @name 7日安全防护平台状态统计 */
const SCREEN_CLIMB_PROCESS_STATISTICS = 'SCREEN_CLIMB_PROCESS_STATISTICS';
/** @name 告警消息列表数据 */
const SCREEN_ALARM_MESSAGE = 'SCREEN_ALARM_MESSAGE';
/** @name 设备故障排行TOP5 */
const SCREEN_DEVICE_ERROR_RANKING = 'SCREEN_DEVICE_ERROR_RANKING';
/** @name 告警消息统计 */
const SCREEN_ALARM_MESSAGE_STATISTICS = 'SCREEN_ALARM_MESSAGE_STATISTICS';
/** @name 安全指数 */
const SCREEN_REGION_SAFE_INDEX = 'SCREEN_REGION_SAFE_INDEX';
/** @name 楼栋今日/预测提升 */
const SCREEN_CLIMB_RISE_AND_PREDICTION = 'SCREEN_CLIMB_RISE_AND_PREDICTION';

/** @name 楼栋提升列表显示条数 */
const LIMIT_PROMOTE = 20;

@Component({
  components: {
    Screen,
    BottomMessage,
    ScreenHeader,
    HighBuilding,
    SevenBuild,
    SafeBuild,
    MessageCount,
    UlList,
    Carousel,
  },
})
export default class Main extends Vue {
  @screenModule.State addMapUrlBoolean;
  @screenModule.State snapMap;
  @screenModule.Mutation SET_ADD_MAP_URL_BOOLEAN;
  LIMIT_PROMOTE = LIMIT_PROMOTE;

  created() {
    this.$store.commit('screen/SET_CURRENT_MATCH_SITE', '');
  }

  messageList = [];
  hanldeMessageBox(item) {
    const data = {
      content: item.errorContent,
      projectLocation: item.projectAddress || item.address,
      principalName: item.principal,
      principalPhone: item.phone,
      title: item.errorType || item.type,
      ...item,
    };
    createModal(() => <ScreenPop detail={data} />, {
      width: `${this.$size(500)}px`,
      title: data.title,
    });
  }

  linkProject(item) {
    this.$router.push({
      path: '/iot/screen/projectBuilding/' + item.projectId,
    });
  }
  todayMoreData() {
    createModal(
      () => (
        <Today
          list={this.promoteListForToday}
          count={this.promoteListForToday.length}
          linkProject={this.linkProject}
        />
      ),
      {
        width: `${this.$size(400)}px`,
        title: '今日提升详情',
        titleStyle: {
          fontSize: `${this.$size(20)}px`,
          fontWeight: '900',
        },
        showButton: false,
        boxEl: this.$refs.screenRef.$el,
      },
    );
  }
  forecastMoreData() {
    createModal(() => <Forecast list={this.promoteListForForecast} />, {
      width: `${this.$size(400)}px`,
      title: '提升预测详情',
      titleStyle: {
        fontSize: `${this.$size(20)}px`,
        fontWeight: '900',
      },
      showButton: false,
    });
  }

  buildList = [];
  deviceErrorList = [];
  buildHeightList = [];
  climbListForSevenDays = [];
  safeIndexList = [];
  alarmMessageListForErrorOrWarn = {};
  promoteListForToday = [];
  promoteListForForecast = [];
  areaChange() {
    this.buildList = [];
    this.buildHeightList = [];
    this.deviceErrorList = [];
    this.climbListForSevenDays = [];
    this.safeIndexList = [];
    this.alarmMessageListForErrorOrWarn = {};
    this.promoteListForToday = [];
    this.promoteListForForecast = [];
  }
  messageHandle(result) {
    const { event, data } = result;
    switch (event) {
      case EVENT_MESSAGE_ERROR:
        this.messageList.push(data);
        break;
      case EVENT_MESSAGE_WARN:
        this.messageList.push(data);
        break;
      case SCREEN_BUILDING_RANKING:
        this.buildList = Object.keys(data)
          .map(v => {
            return {
              site: v,
              count: data[v],
            };
          })
          .filter(v => v.count > 0);
        break;
      case SCREEN_BUILDING_HEIGHT_RANKING:
        this.buildHeightList = data;
        break;
      case SCREEN_CLIMB_PROCESS_STATISTICS:
        this.climbListForSevenDays = data;
        break;
      case SCREEN_ALARM_MESSAGE:
        this.$store.dispatch('screen/getAlarmMessageListByWebsocket', data);
        break;
      case SCREEN_DEVICE_ERROR_RANKING:
        {
          const list = data;
          this.deviceErrorList = list.filter(item => item.count > 0);
        }
        break;
      case SCREEN_ALARM_MESSAGE_STATISTICS:
        this.alarmMessageListForErrorOrWarn = data;
        break;
      case SCREEN_REGION_SAFE_INDEX:
        this.safeIndexList = data.map(e => ({
          ...e,
          timeStamp: e.time,
          time: dayjs(e.time).format('MM.DD'),
        }));
        break;
      case SCREEN_CLIMB_RISE_AND_PREDICTION:
        this.formatPromoteData(data);
        break;
    }
  }

  promoteCarouselOptions = {
    autoplay: false,
  };
  promoteLoading = false;
  formatPromoteData(data) {
    this.promoteLoading = true;
    if (data.prediction.length === 0 && data.toDays.length === 0) {
      this.promoteCarouselOptions.autoplay = true;
      this.promoteListForForecast = [];
      this.promoteListForToday = [];
    }
    if (data.prediction.length) {
      this.promoteListForForecast = this.promoteDataFormat(
        data.prediction,
        '#05D2FE',
        true,
      );
      this.promoteCarouselOptions.autoplay = false;
    }
    if (data.toDays.length) {
      this.promoteListForToday = this.promoteDataFormat(data.toDays);
      this.promoteCarouselOptions.autoplay = false;
    }
  }
  /** @name 预测数据格式化 */
  promoteDataFormat(list, color = '#F4743C', timeDetailed = false) {
    return list.map(item => {
      const projectName = this.sliceData(item.projectName, 8);
      const buildName = this.sliceData(item.buildingName, 2);
      const text = `${projectName} ${buildName}<span style="color: ${color};">（${item.floorNumber}层）</span>`;
      const hours = dayjs(item.promoteDate).format('HH:mm');
      const monthTxt = dateFormatForMonthAndDay({
        currentLongDate: item.nowDate,
        longDate: item.promoteDate,
      });
      const count = timeDetailed ? `${monthTxt} ${hours}` : hours;
      return {
        ...item,
        text,
        count,
      };
    });
  }
  sliceData(data, limit) {
    if (!data) return data;
    if (data.length > limit) {
      return data.slice(0, limit) + '...';
    } else {
      return data;
    }
  }
  promoteCountSort = 0; // 楼栋提升（今日和预测）
  promoteTabChange(index) {
    if (index === 0) {
      this.$refs.todayRef &&
        this.$refs.todayRef.initAnimation(this.promoteListForToday.length);
      this.$refs.forecastRef && this.$refs.forecastRef.clear();
    } else {
      this.$refs.forecastRef &&
        this.$refs.forecastRef.initAnimation(
          this.promoteListForForecast.length,
        );
      this.$refs.todayRef && this.$refs.todayRef.clear();
    }
    this.promoteCountSort = index;
    this.$refs.promoteRef?.handleChangeActiveItem(index);
  }

  @Watch('addMapUrlBoolean', { immediate: true })
  handleChangeAddMapUrlBoolean(value) {
    if (value) {
      this.changeQuerySiteBySearch(this.snapMap);
      this.SET_ADD_MAP_URL_BOOLEAN(false);
    }
  }
  changeQuerySiteBySearch(item) {
    this.$refs.screenRef.handleAddMapUrlQueueLink(item);
    this.$refs.screenRef.site = item.value;
    let addressType = 'PROVINCE';
    if (item.level === 'country') {
      addressType = 'PROVINCE';
    }
    if (item.level === 'province') {
      addressType = 'CITY';
    }
    if (item.level === 'city') {
      addressType = 'AREA';
    }
    this.$refs.screenRef.addressType = addressType;
    this.$refs.screenRef.sendAllEvent();
  }
  changePattern(type) {
    this.$refs.screenRef.pattern = type;
    this.$refs.screenRef.site = ''; // 设置为全国
    this.$refs.screenRef.addressType = 'PROVINCE';
    this.$nextTick(() => {
      this.$refs.screenRef.sendAllEvent();
    });
  }

  messageCountSort = 0; // 7日预警统计和7日隐患事件统计
  messageTabChange(index) {
    this.messageCountSort = index;
    this.$refs.messageCountRef?.handleChangeActiveItem(index);
  }
  get messageCountTxt() {
    if (this.messageCountSort === 0) {
      return this.$t('screen.weeklyWarning');
    } else {
      return this.$t('screen.weeklyEvents');
    }
  }

  regionCountSort = 0; // 区域排行榜（楼栋和故障）
  regionTabChange(index) {
    this.regionCountSort = index;
    this.$refs.regionRef?.handleChangeActiveItem(index);
  }

  showSafeModal(index) {
    const { timeStamp, avgSafetyRateDTOS } = this.safeIndexList[index];
    avgSafetyRateDTOS.sort((a, b) => a.avgSafetyRate - b.avgSafetyRate);
    const columns = [
      {
        title: '项目名称',
        key: 'projectName',
        width: 300,
      },
      {
        title: '安全指数',
        key: 'avgSafetyRate',
        width: 60,
      },
    ];
    const modal = createModal(
      () => (
        <SafeModal
          columns={columns}
          data={avgSafetyRateDTOS.slice(0, 10)}
          onRowClick={item => {
            this.linkProject(item);
            modal.handleClose();
          }}
          showArrow
        />
      ),
      {
        title: `${dayjs(timeStamp).format(
          'YYYY-MM-DD',
        )} 项目安全指数 (最后十名)`,
        width: this.$size(508).toString(),
        showButton: false,
      },
    );
  }
}
</script>
<style lang="less" scoped>
::v-deep {
  .el-tab-pane {
    ::-webkit-scrollbar {
      width: 0 !important;
    }
  }
}
@fontColor: rgba(5, 211, 255, 1);
::v-deep {
  .el-tab-pane {
    ::-webkit-scrollbar {
      width: 0 !important;
    }
  }
}
.moduleTitle {
  position: relative;
  .tab-box {
    font-size: 0.14rem;
    color: rgba(5, 211, 255, 0.5);
    height: 0.4rem;
    line-height: 0.4rem;
    position: absolute;
    right: 0.1rem;
    top: 0;
    z-index: 3;
    .tab-text {
      cursor: pointer;
      margin-left: 0.2rem;
      &:first-child {
        margin-left: 0;
      }
      &.active {
        color: rgba(5, 211, 255, 1);
      }
    }
  }
}
</style>
