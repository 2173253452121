<template>
  <div :class="$style.container">
    <!-- <no-data-chart v-if="isNoData" /> -->
    <div :id="id" :class="$style.chartBox" :style="{ height }"></div>
  </div>
</template>

<script>
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import * as echarts from 'echarts';
import { makeUpData } from '@/utils';
import resize from '@/utils/resize';
import { nowSize } from '@/utils/common.js';
import { globalVueI18n } from '@triascloud/i18n';

const lineOptions = data => {
  data = makeUpData(data, 6, {
    dropCount: 0,
    enterCount: 0,
    quitCount: 0,
    riseCount: 0,
    waitEnterCount: 0,
  });
  const categorys = [];
  const values1 = [];
  const values2 = [];
  const values3 = [];
  const values4 = [];
  const values5 = [];
  let max = 0;
  data.forEach(e => {
    categorys.push(e.time);
    values1.push(e.waitEnterCount);
    values2.push(e.enterCount);
    values3.push(e.riseCount);
    values4.push(e.dropCount);
    values5.push(e.quitCount);
    max = Math.max(
      e.waitEnterCount,
      e.enterCount,
      e.riseCount,
      e.dropCount,
      e.quitCount,
      max,
    );
  });
  max += 10 - (max % 10);
  return {
    tooltip: {
      trigger: 'axis',
      backgroundColor: 'rgba(3,29,63,0.6)', // 通过设置rgba调节背景颜色与透明度
      color: '#fff',
      borderWidth: '1',
      borderColor: '#0DC8FE',
      textStyle: {
        color: '#fff',
      },
      extraCssText: 'border-radius: 0; padding: 10px;',
      position: function(pos, params, dom, rect, size) {
        var obj = { top: nowSize(10) };
        obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 5;
        return obj;
      },
      formatter: function(params) {
        let title = '';
        if (params.length > 0) {
          title = params[0].name;
        }
        let str = '';
        params.forEach(item => {
          str += `
            <div class="seriesName">
              ${item.seriesName}：${item.value}
            </div>
          `;
        });

        return `
          <div class="IndexSevenBuildBox">
            <div class="title">${title}</div>
            ${str}
          </div>`;
      },
      axisPointer: {
        type: 'line',
        z: 1,
        lineStyle: {
          color: '#fff',
          opacity: 0.2,
        },
      },
    },

    legend: {
      itemWidth: nowSize(10),
      itemHeight: nowSize(8),
      top: 'bottom',
      data: [
        {
          name: globalVueI18n.translate('screen.legend.waitEnter'),
          icon: 'rect',
        },
        {
          name: globalVueI18n.translate('screen.legend.enter'),
          icon: 'rect',
        },
        {
          name: globalVueI18n.translate('screen.legend.rise'),
          icon: 'rect',
        },
        {
          name: globalVueI18n.translate('screen.legend.drop'),
          icon: 'rect',
        },
        {
          name: globalVueI18n.translate('screen.legend.quit'),
          icon: 'rect',
        },
      ],
      textStyle: {
        color: '#06CAF7',
        fontSize: nowSize(12),
      },
    },

    grid: {
      x: 50,
      y: 30,
      x2: 20,
      y2: 40,
      bottom: nowSize(40),
    },

    toolbox: {
      feature: {
        saveAsImage: {
          show: false,
        },
      },
    },
    xAxis: {
      type: 'category',
      boundaryGap: true,
      data: categorys,
      // 隐藏刻度线
      axisTick: { show: false },
      axisLine: {
        show: false,
        lineStyle: {
          show: false,
          color: '#fff',
        },
      },
      axisLabel: {
        fontSize: nowSize(12),
      },
    },
    yAxis: {
      show: true,
      min: 0,
      max,
      splitLine: {
        show: true,
        lineStyle: {
          show: true,
          color: ['#1c1e32'],
        },
      },
      type: 'value',
      // 隐藏刻度线
      axisTick: { show: false },
      axisLine: {
        show: false,
        lineStyle: {
          show: true,
          color: '#fff',
        },
      },
      axisLabel: {
        fontSize: nowSize(12),
        formatter: '{a|{value}}',
        width: 20,
        rich: {
          a: {
            align: 'left',
            fontSize: nowSize(12),
          },
        },
      },
    },
    series: [
      {
        name: globalVueI18n.translate('screen.legend.waitEnter'),
        type: 'line',
        smooth: true,
        symbol: 'none',
        data: values1,
        itemStyle: {
          normal: {
            color: '#0DC8FE',
            lineStyle: {
              color: '#0DC8FE',
            },
          },
        },
      },
      {
        name: globalVueI18n.translate('screen.legend.enter'),
        type: 'line',
        smooth: true,
        symbol: 'none',
        data: values2,
        itemStyle: {
          normal: {
            color: '#FBB440',
            lineStyle: {
              color: '#FBB440',
            },
          },
        },
      },
      {
        name: globalVueI18n.translate('screen.legend.rise'),
        type: 'line',
        smooth: true,
        symbol: 'none',
        data: values3,
        itemStyle: {
          normal: {
            color: '#418CF1',
            lineStyle: {
              color: '#418CF1',
            },
          },
        },
      },
      {
        name: globalVueI18n.translate('screen.legend.drop'),
        type: 'line',
        smooth: true,
        symbol: 'none',
        data: values4,
        itemStyle: {
          normal: {
            color: '#01CE61',
            lineStyle: {
              color: '#01CE61',
            },
          },
        },
      },
      {
        name: globalVueI18n.translate('screen.legend.quit'),
        type: 'line',
        smooth: true,
        symbol: 'none',
        data: values5,
        itemStyle: {
          normal: {
            color: '#EC5735',
            lineStyle: {
              color: '#EC5735',
            },
          },
        },
      },
    ],
  };
};
@Component({
  mixins: [resize],
})
export default class SevenBuild extends Vue {
  @Prop({ type: String, default: 'sevenBuild' }) id;
  @Prop({ type: String, default: '100%' }) height;
  @Prop({ type: Array, default: () => [] }) data;
  mounted() {
    this.initChart();
  }
  @Watch('data', { immediate: true })
  dataChange() {
    this.$nextTick(() => {
      this.initChart();
    });
  }

  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  }
  get isNoData() {
    if (!this.data || !this.data.length) {
      if (this.chart) {
        this.chart.dispose();
        this.chart = null;
      }
      return true;
    } else {
      this.$nextTick(this.initChart);
      return false;
    }
  }
  initChart() {
    if (!this.chart)
      this.chart = echarts.init(document.getElementById(this.id));
    const options = lineOptions(this.data);
    this.chart.setOption(options);
    this.chart.resize();
  }
}
</script>
<style lang="less" module>
.container {
  height: 100%;
  position: relative;
}
.noDataBox {
  position: relative;
  width: 100%;
  height: 100%;
  .icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.2rem;
    color: #07d3fd;
  }
}
.chartBox {
  width: 100%;
}
</style>
<style lang="less" scoped>
::v-deep {
  .IndexSevenBuildBox {
    font-size: 0.14rem;
    color: #ffffff;
    padding: 0 0.04rem;
    .title {
      line-height: 0.2rem;
    }
    .seriesName {
      line-height: 0.2rem;
    }
  }
}
</style>
