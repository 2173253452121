<template>
  <div :class="$style.container">
    <no-data-chart v-if="isNoData" />
    <div v-else :id="id" :class="$style.chartBox" :style="{ height }"></div>
  </div>
</template>

<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import * as echarts from 'echarts';
import resize from '@/utils/resize';
import { nowSize } from '@/utils/common.js';
import { globalVueI18n } from '@triascloud/i18n';

let colorList = ['#5580f9', '#00b893', '#f4743c', '#0386ef', '#e34e50'];
const lineOptions = data => {
  const category = data.map(e => e.projectName);
  const values = data.map(e => {
    return {
      ...e,
      value: e.buildingHeight,
    };
  });
  return {
    backgroundColor: '',
    grid: {
      x: 50,
      y: 30,
      x2: 20,
      y2: 40,
    },

    tooltip: {
      backgroundColor: 'rgba(3,29,63,0.6)', // 通过设置rgba调节背景颜色与透明度
      color: '#fff',
      borderWidth: '1',
      borderColor: '#0DC8FE',
      textStyle: {
        color: '#fff',
      },
      formatter: function(params) {
        return `
          <div class="hightBuildItem ${
            params.name.length > 22 ? 'longed' : ''
          }">
            <span class="label">${globalVueI18n.translate(
              'generalView.count.project',
            )}：</span>
            <span class="value">${params.name}</span>
          </div>
          <div class="hightBuildItem">
            <span class="label">${globalVueI18n.translate(
              'electricBoxMock.info.building',
            )}：</span>
            <span class="value">${params.data.buildName}</span>
          </div>
          <div class="hightBuildItem">
            <span class="label">${globalVueI18n.translate(
              'screen.high',
            )}：</span>
            <span class="value">${params.data.buildingHeight +
              ' ' +
              globalVueI18n.translate('screen.meter')} / ${params.data
          .buildingNumber +
          ' ' +
          globalVueI18n.translate('screen.floor')}</span>
          </div>
        `;
      },
      extraCssText: 'border-radius: 0; padding: 10px;',
      position: function(pos, params, dom, rect, size) {
        var obj = { top: 60 };
        obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 5;
        return obj;
      },
    },

    xAxis: {
      data: category,
      // 隐藏刻度线
      axisTick: { show: false },
      axisLine: {
        show: false,
        lineStyle: {
          show: false,
          color: '#ffffff',
        },
      },
      axisLabel: {
        fontSize: nowSize(12),
        interval: 0,
        formatter(val) {
          const toLarge = val.length > 4;
          return toLarge ? val.substr(0, 4) + '…' : val;
        },
      },
    },
    yAxis: {
      splitLine: {
        show: true,
        lineStyle: {
          show: true,
          color: ['#1c1e32'],
        },
      },
      axisTick: { show: false },
      axisLine: {
        show: false,
        lineStyle: {
          show: true,
          color: '#ffffff',
        },
      },
      axisLabel: {
        fontSize: nowSize(11),
        formatter: '{a|{value}}',
        width: 20,
        rich: {
          a: {
            align: 'left',
            fontSize: nowSize(12),
          },
        },
      },
      min: 0,
      scale: true,
      splitNumber: 4,
      splitArea: { show: false },
    },
    series: [
      {
        name: '故障数',
        type: 'bar',
        barWidth: nowSize(18),
        barGap: '30%',
        itemStyle: {
          normal: {
            // 每根柱子颜色设置
            color: function(params) {
              return colorList[params.dataIndex];
            },
          },
        },
        data: values,
      },
    ],
  };
};

@Component({
  mixins: [resize],
})
export default class HighBuilding extends Vue {
  @Prop({ type: String, default: 'highBuilding' }) id;
  @Prop({ type: String, default: '100%' }) height;
  @Prop({ type: Array, default: null }) data;
  mounted() {
    if (this.data && this.data.length) this.initChart();
  }
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  }
  get isNoData() {
    if (!this.data || !this.data.length) {
      if (this.chart) {
        this.chart.dispose();
        this.chart = null;
      }
      return true;
    } else {
      this.$nextTick(this.initChart);
      return false;
    }
  }
  initChart() {
    if (!this.chart)
      this.chart = echarts.init(document.getElementById(this.id));
    const options = lineOptions(this.data);
    this.chart.setOption(options);
  }
}
</script>

<style lang="less" module>
.container {
  height: 100%;
  position: relative;
}
.noDataBox {
  position: relative;
  width: 100%;
  height: 100%;
  .icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.2rem;
    color: #07d3fd;
  }
}
.chartBox {
  width: 100%;
}
</style>
<style lang="less" scoped>
::v-deep {
  .hightBuildItem {
    padding: 0 0.04rem;
    line-height: 0.18rem;
    &.longed {
      word-break: break-all;
      width: 18vw;
      .value {
        width: 16vw;
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .label {
      font-size: 0.12rem;
      color: #ffffff;
    }
    .value {
      font-size: 0.12rem;
      color: #ffffff;
    }
  }
}
</style>
