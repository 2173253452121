<template>
  <no-data-chart v-if="isEmptyObject(data)" />
  <el-carousel
    v-else
    ref="sevenDay"
    style="height: 100%;"
    height="100%"
    :autoplay="true"
    :loop="true"
    :interval="5000"
    arrow="never"
    indicator-position="none"
    @change="handleChange"
  >
    <div>
      <el-carousel-item v-for="(itemList, key) in sortedData" :key="key">
        <ul v-if="itemList && itemList.length" :class="$style.sortList">
          <li
            :class="$style.item"
            v-for="(item, index) in itemList"
            :key="index"
          >
            <div :class="[$style.left, $style.substr]">
              <span :class="$style.num">{{ index + 1 }}.</span>
              <span :class="$style.text">{{ formatEventType(item) }}</span>
            </div>
            <div :class="$style.right" @click.stop="handleCount(item)">
              {{ item.countNum }}次
            </div>
          </li>
        </ul>
        <no-data-chart v-else />
      </el-carousel-item>
    </div>
  </el-carousel>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import { ensureArray, generateMap } from '@triascloud/utils';
import { isEmptyObject } from '@/utils';
import { createModal } from '@triascloud/x-components';
import WarnErrorDetail from '@/views/iot/screen/components/warn-error';

@Component()
export default class MessageCount extends Vue {
  @Prop({ default: () => {}, type: Object }) data;
  @Prop({ default: function() {}, type: Function }) handleChange;

  created() {
    this.isEmptyObject = isEmptyObject;
  }

  get sortedData() {
    if (this.data.maps) {
      delete this.data.maps;
    }
    return generateMap(
      Object.keys(this.data),
      key => key,
      key =>
        ensureArray(this.data[key])
          .slice()
          .sort((a, b) => b.countNum - a.countNum),
    );
  }

  formatEventType(item) {
    let result = '';
    if (item.type === 'ERROR') {
      switch (item.sensorType) {
        case 'GRAVITY':
          result = this.$t('electricBox.error.BranchControlBoxOverload');
          break;
        case 'STANCE':
          result = this.$t('electricBox.error.SubBoxTilt');
          break;
        case 'LOST_LOAD':
          result = this.$t('electricBox.error.SubBoxLossOfLoad');
          break;
        case 'SUB_CONTROL_BOX_DROPPED':
          result = this.$t('electricBox.error.SubBoxIsOffline');
          break;
        case 'SUB_CONTROL_BOX_STOP':
          result = this.$t('electricBox.error.EmergencyStopOfSubBox');
          break;
        case 'MAIN_CONTROL_BOX_STOP':
          result = this.$t('electricBox.error.EmergencyStopOfMainControlBox');
          break;
      }
    }
    if (item.type === 'WARN') {
      switch (item.sensorType) {
        case 'GRAVITY':
          result = this.$t('electricBox.warn.overloadEvent');
          break;
        case 'STANCE':
          result = this.$t('electricBox.warn.tiltEvent');
          break;
        case 'LOST_LOAD':
          result = this.$t('electricBox.warn.underloadEvent');
          break;
        case 'TOTAL_LOAD':
          result = this.$t('electricBox.warn.totalLoad');
          break;
        case 'TOTAL_CURRENT':
          result = this.$t('electricBox.warn.totalElectricity');
          break;
        case 'WEATHER':
          result = this.$t('electricBox.warn.weatherEvents');
          break;
        case 'SUB_CONNECTION':
          result = this.$t('electricBox.warn.SubBoxWarning');
          break;
        case 'LOCATION':
          result = this.$t('electricBox.warn.LocationWarning');
          break;
        case 'CLOSEDTOP':
          result = this.$t('electricBox.warn.CappingWarning');
          break;
      }
    }
    return result;
  }

  handleChangeActiveItem(index) {
    this.$refs.sevenDay && this.$refs.sevenDay.setActiveItem(index);
  }
  handleCount(item) {
    createModal(() => <WarnErrorDetail dataList={item.details} />, {
      title: this.$t('electricBox.StatisticalDetails'),
      width: 900,
      className: this.$style['x-warn-error-detail'],
    });
  }
}
</script>
<style lang="less" module>
.sortList {
  padding: 0.1rem 0.2rem;
  .item {
    font-size: 0.16rem;
    line-height: 0.3rem;
    display: flex;
    justify-content: space-between;
    &.multi {
      padding: 0 0.5rem 0 0.2rem;
    }
    &.active {
      background: rgba(5, 211, 255, 0.1);
    }
    .left {
      flex: 1;
      margin-right: 0.1rem;
      height: 0.3rem;
      cursor: default;
    }
    .right {
      cursor: pointer;
    }
  }
}
.x-warn-error-detail {
  :global {
    .ant-modal-content {
      background-color: #031d3f;
      .ant-modal-header {
        background-color: #1d3453;
        .ant-modal-title {
          color: #05d2fe;
        }
      }
      .ant-modal-close {
        color: #05d2fe;
      }
      .ant-table-thead > tr > th {
        color: #05d2fe;
      }
      .ant-table-tbody > tr > td {
        color: #05d2fe;
        background-color: #1d3453;
        border-bottom-color: #1d3453;
      }
      .ant-pagination-item,
      .ant-pagination-next .ant-pagination-item-link,
      .ant-pagination-prev .ant-pagination-item-link {
        border-color: #05d2fe;
      }
    }
  }
}
</style>
