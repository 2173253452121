<template>
  <div :class="$style.box" ref="boxRef">
    <pie-chart
      v-if="formatList && formatList.length"
      :list="results"
      :height="$size(300) + 'px'"
    />
    <div v-if="formatList && formatList.length" :class="$style.txtBox">
      未来3天预计提升：{{ formatList.length }}栋
    </div>
    <div v-else :class="$style.noDataBox">
      <no-data-chart />
      <p :class="$style.desc">未来3日暂无提升楼栋</p>
    </div>
  </div>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import dayjs from 'dayjs';
import { dateFormatForMonthAndDay } from '@/utils';
import PieChart from './pie-chart';
@Component({
  components: {
    PieChart,
  },
})
export default class forecast extends Vue {
  @Prop({ type: Array, default: () => [] }) list;

  get formatList() {
    const results = [];
    this.list.forEach(item => {
      const diffDay = dateFormatForMonthAndDay({
        currentLongDate: item.nowDate,
        longDate: item.promoteDate,
        extra: true,
      });
      const dayKey = ['', '明天', '后天', '大后天'];
      if (dayKey.findIndex(i => i === diffDay) > 0) {
        results.push({
          ...item,
          name: diffDay,
        });
      }
    });
    return results;
  }

  get results() {
    const result = [];
    const oneList = this.formatList.filter(item => item.name === '明天');
    const twoList = this.formatList.filter(item => item.name === '后天');
    const threeList = this.formatList.filter(item => item.name === '大后天');
    if (oneList.length) {
      result.push({
        name: '明天',
        percentage: this.mathFloor(oneList.length, this.formatList.length),
        value: oneList.length,
      });
    }
    if (twoList.length) {
      result.push({
        name: '后天',
        percentage: this.mathFloor(twoList.length, this.formatList.length),
        value: twoList.length,
      });
    }
    if (threeList.length) {
      result.push({
        name: dayjs(threeList[0].promoteDate).format('M月D日'),
        percentage: this.mathFloor(threeList.length, this.formatList.length),
        value: threeList.length,
      });
    }
    return result;
  }

  mathFloor(count, total) {
    return Math.floor((count / total) * 100);
  }
}
</script>
<style lang="less" module>
.box {
  width: 100%;
  .txtBox {
    padding: 0.3rem 0.2rem 0.4rem;
    text-align: center;
    font-weight: 700;
    font-size: 0.16rem;
  }

  .noDataBox {
    height: 2.5rem;
    & > div {
      height: 1.8rem;
    }
    .desc {
      text-align: center;
      font-size: 0.16rem;
      color: #fff;
    }
  }
}
</style>
