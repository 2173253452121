<template>
  <section class="screen-warp__roll-msg">
    <el-carousel
      height="50px"
      direction="vertical"
      :interval="5000"
      indicator-position="none"
    >
      <el-carousel-item v-for="(item, index) in list" :key="index">
        <div class="msg">
          <i class="msg-icon TC tc-icon-banebn" />
          <span class="msg-content"
            >{{ item.type }}：{{ timeFormat(item.time) }}，{{
              item.errorContent
            }}</span
          >
          <span class="view pointer" @click="hanldeMessageBox(item)">{{
            $t('camera.look')
          }}</span>
        </div>
      </el-carousel-item>
    </el-carousel>
  </section>
</template>
<script>
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import dayjs from 'dayjs';

@Component()
export default class BottomMessage extends Vue {
  @Prop({ type: Function, default: function() {} }) hanldeMessageBox;

  timeFormat(value) {
    if (value) {
      return dayjs(value).format('YYYY-MM-DD HH:mm:ss');
    }
    return value;
  }
  eventTypeFormat(value) {
    const key = {
      WARN: this.$t('generalView.key.warning'),
      ERROR: this.$t('screen.faults'),
    };
    if (value) {
      return key[value];
    } else {
      return key['WARN'];
    }
  }

  @Prop({ type: Array, default: () => [] }) messageList;
  list = [];
  @Watch('messageList', { immediate: true })
  onMessageChange(value) {
    for (let i = 0, len = value.length; i < len; i++) {
      if (this.list.length > 0) {
        this.list.shift();
      }
    }
    this.list.push(...value);
  }
  @Watch('faultArray', { immediate: true })
  onFaultArray() {
    this.initList();
  }
  @Watch('warnArray', { immediate: true })
  onWarnArray() {
    this.initList();
  }
  get faultArray() {
    return this.$store.state.screen.faultArray;
  }
  get warnArray() {
    return this.$store.state.screen.warnArray;
  }
  initList() {
    let array = [];
    // 限制条数为10
    const MAX_SIZE = 10;
    if (this.faultArray.length > MAX_SIZE) {
      const list = this.faultArray.slice(MAX_SIZE);
      array.push(...list);
    } else {
      array.push(...this.faultArray);
    }
    if (this.warnArray.length > MAX_SIZE) {
      const list = this.warnArray.slice(MAX_SIZE);
      array.push(...list);
    } else {
      array.push(...this.warnArray);
    }
    this.list = array.map(v => {
      return {
        ...v,
        errorContent: v.content,
      };
    });
  }
}
</script>
<style lang="less" scoped>
@fff: #fff;
@bgColor: #02051b;
@fontColor: rgba(5, 211, 255, 1);
// 信息滚动
.screen-warp__roll-msg {
  height: 0.64rem;
  line-height: 0.64rem;
  font-size: 0.16rem;
  width: 100%;
  border-top: 0.01rem solid rgba(13, 200, 254, 0.5);
  position: relative;
  z-index: 3;
  color: #fff;
  // background-color: @bgColor;
  text-align: center;
  ::v-deep {
    .el-carousel__container {
      height: 0.64rem !important;
    }
  }
  .msg {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 0.4rem;
    box-sizing: border-box;
    .msg-icon {
      display: inline-block;
      font-size: 0.14rem;
      margin-right: 0.05rem;
      position: relative;
      &::before {
        position: absolute;
        left: -0.14rem;
        top: 50%;
        transform: translateY(-47%);
      }
    }
    .msg-content {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .view {
      color: @fontColor;
      margin-left: 0.05rem;
      cursor: pointer;
      flex-basis: 0.6rem;
    }
  }
}
</style>
