<template>
  <div class="chartMap" ref="chartMapRef">
    <div
      class="echarts chartMapBox"
      :style="{ height: height, width: width }"
      v-loading="loading"
    >
      <div :id="id" :style="{ height: height, width: width }"></div>
    </div>
    <div class="backMapWrap" v-if="mapUrlQueueSize !== 1">
      <span class="backMapLabel" @click="backTopMapArea">{{
        $t('screen.btn.back')
      }}</span>
    </div>
    <ul class="tipBoxWrap">
      <li
        class="tipItem"
        v-for="(item, index) in buttonGroups"
        :key="item.key"
        @click="handleSelectButton(index, item)"
      >
        <span
          class="iconBox"
          :style="[
            item.selected
              ? { backgroundColor: item.color }
              : { backgroundColor: '#929292' },
          ]"
        ></span>
        <span class="labelBox">{{ $t(item.label) }}</span>
      </li>
    </ul>
  </div>
</template>
<script>
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import * as echarts from 'echarts';
import throttle from 'lodash/throttle';
import { fetchLongProvinceName } from '@/assets/js/area.js';
import { getGeoData, getChinaData } from '@/services/oss/oss.js';
import { screenModule } from '@/enum/store.js';
import { gcj02tobd09 } from '@/utils';
import {
  Color_Rising,
  Color_Waiting,
  Color_Building,
  Color_Outing,
  Color_Electricbox,
  LEVEL_RISING,
  LEVEL_TYPE_COUNTRY,
  LEVEL_TYPE_PROVINCE,
  LEVEL_TYPE_CITY,
  LEVEL_WAITING,
  LEVEL_BUILDING,
  LEVEL_OUTING,
  LEVEL_ELECTRIC_BOX,
  getAddressType,
} from './final';
import { debounce } from 'lodash-decorators';

/** @name 缓存区域和项目的tooltip */
const areaCountMap = new Map();
const projectMap = new Map();

// const hasRegisterData = new Map(); // 默认注册

/** @name 缓存websocket传入的项目 */
const projectListMap = new Map();

/** @name 项目数据加载完成事件 */
const LOADED_COMPLETE_PROJECT_LIST = 'project-list-register';
/** @name 地图加载完成事件 */
const LOADED_COMPLETE_MAP = 'map-register';

function noop() {}

/**
 * @typedef QueueMap 地图层级Map
 * @property {string} level 地图的层级
 * @property {string} adcode 地图的地理编码
 * @property {string} value 地图的名称
 * @property {string} title 地图的标题
 */

@Component()
export default class SubcontractMap extends Vue {
  /** @type QueueMap */
  @screenModule.Getter lastMapUrlQueueItem;
  @screenModule.Getter waitingButton;
  @screenModule.Getter buildingButton;
  @screenModule.Getter electricBoxButton;
  @screenModule.Getter outtingButton;
  @screenModule.Getter risingButton;
  @screenModule.State mapUrlQueue; // 跳转地址队列
  @screenModule.State buttonGroups;
  @screenModule.Action addMapUrlQueue;
  @screenModule.Action deleteMapUrlQueueOfPop;
  @screenModule.Action updateButtonGroups;

  @Prop({ type: String, default: 'SubcontractMap' }) id;
  @Prop({ type: String, default: '100%' }) width;
  @Prop({ type: String, default: '100%' }) height;
  @Prop({ type: Function, default: noop }) handleMoveMapForProject;
  @Prop({ type: Function, default: noop }) handleMoveMapForArea;

  async mounted() {
    this.initSet();
    this.listeners();
    // 监听 window.resize 事件
    // TODO 防抖
    window.addEventListener(
      'resize',
      throttle(() => {
        this.changeResize();
      }),
      200,
    );
    document.addEventListener('visibilitychange', this.refreshMap);
  }
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
    document.removeEventListener('visibilitychange', this.refreshMap);
  }
  refreshMap(e) {
    if (e.target.visibilityState === 'visible') {
      this.triggerRegisterEvent(this.projectObject);
    }
  }

  type = LEVEL_TYPE_COUNTRY;
  initSet() {
    if (this.mapUrlQueue.length === 0) {
      this.addMapUrlQueue({
        level: this.type,
        adcode: '100000',
        value: '',
        title: '全国',
      });
    } else {
      /**
       * 1、设置type类型（当前所处地图层级）
       */
      this.type = this.lastMapUrlQueueItem.level;
    }
  }

  hasRegisterData = true; // 默认注册
  listeners() {
    this.$on(LOADED_COMPLETE_MAP, result => {
      const data = projectListMap.get(result);
      if (data) {
        setTimeout(() => {
          this.triggerRegisterEvent(data);
        }, 0);
      } else {
        this.hasRegisterData = false;
      }
    });
    this.$on(LOADED_COMPLETE_PROJECT_LIST, data => {
      if (!this.hasRegisterData) {
        this.hasRegisterData = true;
        this.triggerRegisterEvent(data);
      }
    });
  }

  async handleSelectButton(index, item) {
    this.updateButtonGroups({
      index,
      selected: !item.selected,
    });
    // await this.initLoadMap();
    this.triggerRegisterEvent(this.projectObject);
  }

  get mapUrlQueueSize() {
    return this.mapUrlQueue.length;
  }

  projectCacheQueue = []; // tooltip 项目key缓存
  /** @name 查看项目的详情统计数据 */
  handleMoveDataByProject(data) {
    const fIndex = this.projectCacheQueue.findIndex(v => v === data.id);
    this.projectCacheQueue.splice(fIndex, 1);
    const process =
      this.currentPointParams.data && this.currentPointParams.data.process;
    const result = { ...data, process };
    const value = this.projectDialogHtml(result);
    projectMap.set(data.id, result);
    this.pointCallback(this.pointTicket, value);
  }
  pointTicket = undefined;
  pointCallback = noop;
  currentPointParams = {};
  /** @name 设置项目的series */
  formatSeriesObject() {
    return {
      symbol: 'circle',
      symbolSize: 24,
      type: 'scatter', // series图表类型 effectScatter动画
      coordinateSystem: 'geo',
      data: [],
      tooltip: {
        backgroundColor: 'rgba(3,29,63,0.6)',
        color: '#fff',
        borderWidth: '1',
        borderColor: 'rgba(13, 200, 254, 0.6)',
        textStyle: {
          color: '#fff',
          fontSize: this.$size(14),
        },
        extraCssText: 'border-radius: 0; ',
        trigger: 'item',
        formatter: (params, ticket, callback) => {
          this.pointTicket = ticket;
          this.pointCallback = callback;
          this.currentPointParams = params;

          const projectCacheOne = this.projectCacheQueue.find(
            v => v === params.data.id,
          );
          if (projectCacheOne) {
            return 'Loading';
          }
          if (projectMap.has(params.data.id)) {
            const result = projectMap.get(params.data.id);
            return this.projectDialogHtml(result);
          } else {
            this.reqDebounce(this.reqMapProjectApi, params.data.id);
            return 'Loading';
          }
        },
      },
    };
  }
  /** @name 鼠标悬浮在项目展示的弹窗 */
  projectDialogHtml(data) {
    let str = `<div class="areaBox">`;
    str += `
      <div class="titleTool">${data.name}</div>
        <div class="toolTipBuild">
          <span class="labelTip">${this.$t(
            'matterwebset.electricbox.buildingnum',
          )}：</span>
          <span class="valueTip">${data.buildQuantity}</span>
        </div>
        <div class="toolTipBuild">
          <span class="labelTip">${this.$t(
            'matterwebset.generalscreen.warningnum',
          )}：</span>
          <span class="valueTip">${data.alarmQuantity}</span>
        </div>
    `;
    try {
      if (
        data.process === 'BUILD_LOADING' &&
        (Object.prototype.toString.call(data.thisMonthSafeIndex) ===
          '[object Number]' ||
          data.thisMonthSafeIndex)
      ) {
        str += `
          <div class="toolTipBuild">
            <span class="labelTip">${this.$t(
              'screen.count.monthlySafeRate',
            )}：</span>
            <span class="valueTip">${data.thisMonthSafeIndex}%</span>
          </div>`;
      }
      // eslint-disable-next-line no-empty
    } catch (error) {}
    str += `</div>`;
    return str;
  }

  areaCacheQueue = []; // tooltip 地区key缓存
  /** @name 查看区域的统计数据 */
  handleMoveDataByArea(data) {
    const key = `${data.area || data.provincesAndCities}`; //`${this.currentArea.name}`;
    const fIndex = this.areaCacheQueue.findIndex(v => v === key);
    this.areaCacheQueue.splice(fIndex, 1);
    areaCountMap.set(key, data);
    this.areaCallback(this.areaTicket, this.areaDialogHtml(data));
  }
  areaTicket = undefined;
  areaCallback = noop;
  currentArea = {};
  getFormatter(params, ticket, callback) {
    this.areaCallback = callback;
    this.areaTicket = ticket;
    this.currentArea = params;
    // 1、从区域表中获取省市的名称
    // 2、缓存已经存在的信息;
    const key = `${params.name}`;
    // 防止tooltip重复请求websocket，（重复请求的原因是areaCountMap，还没有对应的值）
    const areaCacheOne = this.areaCacheQueue.find(v => v === key);
    if (areaCacheOne) {
      return 'Loading';
    }
    if (areaCountMap.has(key)) {
      return this.areaDialogHtml(areaCountMap.get(key));
    } else {
      this.reqDebounce(this.reqMapApi, params);
      return 'Loading';
    }
  }
  //项目和地图省份防抖
  @debounce(500)
  reqDebounce(callback, params) {
    callback(params);
  }

  reqMapProjectApi(id) {
    this.projectCacheQueue.push(id);
    this.handleMoveMapForProject(id);
  }

  reqMapApi(params) {
    this.areaCacheQueue.push(params.name);
    const object = {
      provincesAndCities: '',
      area: '',
    };
    if (this.type === LEVEL_TYPE_COUNTRY) {
      object.provincesAndCities = params.name;
      object.area = '';
    }
    if (this.type === LEVEL_TYPE_PROVINCE) {
      let extraCity = ['重庆市', '上海市', '天津市', '北京市'];
      if (extraCity.indexOf(this.lastMapUrlQueueItem.value) >= 0) {
        object.provincesAndCities = this.lastMapUrlQueueItem.value;
        object.area = params.name;
      } else {
        object.provincesAndCities = params.name;
        object.area = '';
      }
    }
    if (this.type === LEVEL_TYPE_CITY) {
      object.provincesAndCities = this.lastMapUrlQueueItem.value;
      object.area = params.name;
    }
    this.handleMoveMapForArea(object);
  }
  /** @name 设置地图的echarts配置，包含了区域 */
  defaultOptions(geo) {
    return new Promise(resolve => {
      const options = {
        tooltip: {
          trigger: 'item',
          backgroundColor: 'rgba(3,29,63,0.6)', // 通过设置rgba调节背景颜色与透明度
          color: '#fff',
          borderWidth: '1',
          borderColor: '#0DC8FE',
          textStyle: {
            color: '#fff',
            fontSize: this.$size(14),
          },
          extraCssText: 'border-radius: 0;',
          formatter: this.getFormatter,
        },
        geo: {
          map: geo,
          scaleLimit: {
            min: 0.4,
            max: 38,
          },
          roam: false,
          itemStyle: {
            areaColor: 'rgba(3, 38, 63, 0.3)',
            borderColor: '#05D3FF',
          },
          emphasis: {
            itemStyle: {
              areaColor: 'rgba(5, 193, 236, 0.4)',
            },
            label: {
              show: false,
            },
          },
          select: {
            itemStyle: {
              areaColor: 'rgba(5, 193, 236, 0.4)',
            },
            label: {
              show: false,
            },
          },
          label: {
            show: false,
          },
        },
        series: [],
      };
      /** @name 待进场项目 */
      const waitingSeries = Object.assign({}, this.formatSeriesObject(), {
        symbolSize: this.$size(8),
        data: [],
        itemStyle: {
          color: Color_Waiting,
        },
        z: 11,
      });
      /** @name 已退场项目 */
      const outingSeries = Object.assign({}, this.formatSeriesObject(), {
        symbolSize: this.$size(8),
        data: [],
        itemStyle: {
          color: Color_Outing,
        },
        z: 10,
      });
      /** @name 已进场项目 */
      const buildingSeries = Object.assign({}, this.formatSeriesObject(), {
        symbolSize: this.$size(10),
        data: [],
        itemStyle: {
          color: Color_Building,
        },
        z: 14,
      });
      /** @name 提升中项目 */
      const risingSeries = Object.assign({}, this.formatSeriesObject(), {
        type: 'effectScatter',
        symbolSize: this.$size(10),
        data: [],
        itemStyle: {
          color: Color_Rising,
        },
        rippleEffect: {
          scale: 2.8,
        },
        z: 16,
      });
      /** @name 已进场有安监终端项目 */
      const electricBoxSeries = Object.assign({}, this.formatSeriesObject(), {
        symbolSize: this.$size(10),
        data: [],
        itemStyle: {
          color: Color_Electricbox,
        },
        z: 16,
      });
      const series = [
        waitingSeries,
        outingSeries,
        buildingSeries,
        electricBoxSeries,
        risingSeries,
      ];
      series.push({
        name: '楼栋分布',
        type: 'map',
        roam: true,
        geoIndex: 0,
        data: [],
        dataRange: {
          show: false,
        },
      });
      options.series = series;
      resolve(options);
    });
  }
  /** @name 鼠标悬浮在地图展示的弹窗 */
  areaDialogHtml(data) {
    let str = `<div class="titleTool">${data.area ||
      data.provincesAndCities}</div>`;
    if (data.projectQuantity >= 0) {
      str += `
        <div class="toolTipBuild">
          <span class="labelTip">${this.$t('screen.count.projects')}：</span>
          <span class="valueTip">${data.projectQuantity}</span>
        </div>
        <div class="toolTipBuild">
          <span class="labelTip">${this.$t('screen.warningTotalcount')}：</span>
          <span class="valueTip">${data.alarmQuantity}</span>
        </div>
        <div class="toolTipBuild">
          <span class="labelTip">${this.$t(
            'screen.count.startedProjects',
          )}：</span>
          <span class="valueTip">${data.underProjectQuantity}</span>
        </div>
        <div class="toolTipBuild">
          <span class="labelTip">${this.$t(
            'screen.count.startedBuilding',
          )}：</span>
          <span class="valueTip">${data.underBuildQuantity}</span>
        </div>
      `;
      if (data.thisMonthSafeIndex) {
        str += `
          <div class="toolTipBuild">
            <span class="labelTip">${this.$t(
              'screen.count.monthlySafeRate',
            )}：</span>
            <span class="valueTip">${data.thisMonthSafeIndex}%</span>
          </div>
        `;
      }
      str += `
        <div class="toolTipBuild">
          <span class="labelTip">${this.$t(
            'matterwebset.generalscreen.warningnum7',
          )}：</span>
          <span class="valueTip">${data.beforeSevenDayAlarmQuantity}</span>
        </div>
      `;
    } else {
      str += `<div class="toolTipBuild">${this.$t(
        'common.label.emptyContent',
      )}</div>`;
    }
    return `
      <div class="areaBox">
        ${str}
      </div>
    `;
  }

  /** @name 添加路由跳转的数据 */
  async addMapUrlQueueLink(linkData) {
    this.addMapUrlQueue(linkData);
    this.type = linkData.level;
  }

  /** @name 初始化地图 */
  async initLoadMap() {
    await this.initChart();
    this.dbclickLoading = true;
  }
  resize(height = '100%', width = '100%') {
    this.chart &&
      this.chart.resize({
        height,
        width,
      });
  }
  get hasChangeFullScrren() {
    return this.$store.state.screen.hasChangeFullScrren;
  }
  @Watch('hasChangeFullScrren')
  onHasChangeFullScrren() {
    this.changeResize();
  }
  /** @name 针对全屏事件，地图resize；使用setTimeout是等待地图resize结束 */
  changeResize() {
    setTimeout(() => {
      const rect = this.$refs.chartMapRef.getBoundingClientRect();
      this.resize(rect.height, rect.width);
    }, 100);
  }
  chart = null;
  loading = false;
  async initChart() {
    this.loading = false;
    if (!this.chart) {
      this.chart = echarts.init(document.getElementById(this.id));
    }
    const selected = await this.registerMap();
    const options = await this.defaultOptions(selected);
    // zoom: 1.5,
    // top: '30%',
    if (selected === '100000') {
      options.geo.zoom = 1.5;
      options.geo.top = '30%';
    } else {
      options.geo.zoom = 1;
      options.geo.top = '10%';
    }
    this.chart.setOption(options, true, true);

    this.handleEchartsEventListener();
  }
  dbclickLoading = true;
  handleEchartsEventListener() {
    this.chart.off('click'); // 点击事件之前，要释放之前的点击事件
    this.chart.off('dblclick');
    // 单击事件
    this.chart.on('click', params => {
      // 跳转到项目去
      const path = this.$route.path.startsWith('/iot/screen')
        ? 'projectBuilding'
        : 'buildingFloor';
      if (params.data && params.data.id) {
        this.$store.dispatch('screen/setBackRouterLinkAction', '/iot/screen');
        this.$router.push({
          path: `/iot/screen/${path}/${params.data.id}`,
        });
      }
    });
    // 双击事件切换地图
    this.chart.on('dblclick', async params => {
      if (!this.dbclickLoading) return;
      this.dbclickLoading = false;
      if (this.type === LEVEL_TYPE_CITY) {
        return;
      }

      await this.handleMapUrl(params);

      this.type = getAddressType(this.type);

      this.handleEmit({
        site: this.getSite(params.name),
        type: getAddressType(this.type),
      });
    });
  }
  /**
   * @name 处理入参的值
   * 当请求是省份时，需要省份的全称
   */
  getSite(name) {
    const siteName = fetchLongProvinceName(name);
    return siteName ? siteName : name;
  }
  async handleMapUrl(params) {
    // error: 双击项目（地图的导致的错误）
    if (!params.name) {
      return false;
    }
    const res = this.areaArray.find(v => v.name === params.name);
    let prefix = res.adcode;

    this.addMapUrlQueue({
      level: getAddressType(this.type),
      adcode: prefix,
      value: params.name,
      title: params.name,
    });
  }
  cityAreaData = {};
  // [{adcode, name}]
  areaArray = [];
  async registerMap() {
    try {
      let selected = this.lastMapUrlQueueItem.adcode;
      let areaJson = {};
      if (selected === '100000') {
        areaJson = await getChinaData(`/static/map/${selected}.json`);
      } else {
        areaJson = await getGeoData(selected);
      }
      this.fetchAreaAdcode(areaJson);

      echarts.registerMap(selected, areaJson);
      this.$emit(LOADED_COMPLETE_MAP, selected);
      return selected;
    } catch (error) {
      return 'china';
    }
  }
  fetchAreaAdcode(array = []) {
    // features--properties--{adcode, name}
    try {
      const areaArray = [];
      array.features.forEach(area => {
        const { adcode, name } = area.properties;
        areaArray.push({
          adcode,
          name,
        });
      });
      this.areaArray = areaArray;
    } catch (error) {
      /* eslint-disable-next-line */
      console.error(error);
    }
  }

  projectObject = {};
  /**
   * @name echart.appendData，动态添加数据
   * @description
   * { Number } seriesIndex 0 待进场项目
   * { Number } seriesIndex 1 已退场项目
   * { Number } seriesIndex 2 已进场项目
   * { Number } seriesIndex 3 已进场有安监终端项目
   */
  initData(data) {
    if (!data) return;
    this.projectObject = this.formatProjectData(data);
    projectListMap.set(this.lastMapUrlQueueItem.adcode, this.projectObject);
    this.$emit(LOADED_COMPLETE_PROJECT_LIST, this.projectObject);
  }
  /** @name 格式化项目数据 */
  formatProjectData(data) {
    const waitList = []; // 待进场项目-WAIT_ENTER
    const outtingList = []; // 已退场项目-QUIT
    const buildingList = []; // 已进场项目-BUILD_LOADING
    const electricBoxList = []; // 已进场有安监终端项目-DEVICE_BIND
    const risingList = []; // 提升中项目-DEVICE_BIND
    if (Array.isArray(data)) {
      data.forEach(v => {
        const { lng, lat } = gcj02tobd09(v.lng, v.lat);
        if (v.process === LEVEL_WAITING) {
          waitList.push({
            ...v,
            value: [lng, lat],
          });
        }
        if (v.process === LEVEL_OUTING) {
          outtingList.push({
            ...v,
            value: [lng, lat],
          });
        }
        if (v.process === LEVEL_BUILDING) {
          buildingList.push({
            ...v,
            value: [lng, lat],
          });
        }
        if (v.process === LEVEL_RISING) {
          risingList.push({
            ...v,
            value: [lng, lat],
          });
        }
        if (v.process === LEVEL_ELECTRIC_BOX) {
          electricBoxList.push({
            ...v,
            value: [lng, lat],
          });
        }
      });
    }

    return {
      waitList,
      outtingList,
      buildingList,
      electricBoxList,
      risingList,
    };
  }
  triggerRegisterEvent(data) {
    let options = this.chart.getOption();
    options.geo[0].roam = true;
    let pointArr = [
      this.waitingButton ? data.waitList : [],
      this.outtingButton ? data.outtingList : [],
      this.buildingButton ? data.buildingList : [],
      this.electricBoxButton ? data.electricBoxList : [],
      this.risingButton ? data.risingList : [],
    ];
    for (let i = 0; i < pointArr.length; i++) {
      options.series[i].data = pointArr[i];
    }
    if (this.lastMapUrlQueueItem.adcode === '100000') {
      options.geo.zoom = 1.5;
      options.geo.top = '30%';
    } else {
      options.geo.zoom = 1;
      options.geo.top = '10%';
    }
    this.chart.setOption(options);
  }

  clickDiff = false;
  /** @name 返回上一级地图（地图区域跳转） */
  async backTopMapArea() {
    if (this.clickDiff) return false;
    this.deleteMapUrlQueueOfPop();
    this.loading = true;
    let site = this.lastMapUrlQueueItem.value;
    this.type = this.lastMapUrlQueueItem.level;
    if (this.type === LEVEL_TYPE_COUNTRY) {
      site = '';
    }
    this.handleEmit({ site, type: getAddressType(this.type) });
    this.clickDiff = true;

    setTimeout(() => {
      this.clickDiff = false;
    }, 500);
  }

  handleEmit(options) {
    this.$emit('map-area-change', options);
  }
}
</script>

<style lang="less" scoped>
// 地图样式
.chartMap {
  background: url('~@/views/iot/screen/assets/images/map-bg.png') center center
    no-repeat;
  background-size: 80%;
  height: 100%;
  width: 100%;
  .chartMapBox {
    ::v-deep .el-loading-mask {
      background-color: rgba(2, 5, 7, 0.7) !important;
    }
  }
  .backMapWrap {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    .backMapLabel {
      display: inline-block;
      line-height: 1;
      white-space: nowrap;
      cursor: pointer;
      background: #fff;
      border: 0.01rem solid #eee;
      color: #ffffff;
      text-align: center;
      box-sizing: border-box;
      transition: 0.1s;
      font-weight: 500;
      padding: 0.12rem 0.2rem;
      font-size: 0.14rem;
      border-radius: 0.04rem;
      background-color: rgba(13, 200, 254, 0.5);
      border-color: rgba(13, 200, 254, 0.5);
    }
  }
  .tipBoxWrap {
    position: absolute;
    bottom: 0;
    left: 26vw;
    .tipItem {
      display: block;
      list-style: none;
      text-align: left;
      margin-bottom: 0.15rem;
      cursor: pointer;
      &:last-of-type {
        margin-bottom: 0;
      }
      .iconBox {
        display: inline-block;
        width: 0.14rem;
        height: 0.14rem;
        border-radius: 100%;
        vertical-align: middle;
        margin-right: 0.05rem;
      }
      .labelBox {
        font-size: 0.14rem;
        color: #ffffff;
        display: inline-block;
        vertical-align: middle;
      }
    }
  }
  ::v-deep .areaBox {
    margin: -0.05rem;
    padding: 0.2rem;
    background: rgba(3, 29, 63, 0.6);
    border-radius: 0.03rem;
    box-sizing: border-box;
    white-space: initial;
    width: 2rem;
    box-shadow: 0 0.02rem 0.06rem 0 rgba(114, 124, 245, 0.5);
    .titleTool {
      color: rgba(13, 200, 254, 1);
      font-size: 0.18rem;
      font-weight: bolder;
      line-height: 0.24rem;
      padding-bottom: 0.16rem;
      word-break: break-all;
    }
    .toolTipBuild {
      font-size: 0.14rem;
      margin-bottom: 0.04rem;
      color: #ffffff;
      position: relative;
      .labelTip {
        width: 1rem;
        font-size: 0.14rem;
        line-height: 0.24rem;
        display: inline-block;
        color: rgba(255, 255, 255, 0.8);
      }
      .valueTip {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
      }
    }
  }
}
</style>
