<template>
  <section class="mainScreen">
    <section class="screenBox">
      <section class="screenHeader">
        <slot name="header"></slot>
      </section>
      <template v-if="pattern === PATTERN_DETAIL">
        <section class="screenMain">
          <section class="screenContent">
            <div class="screenMainLeft">
              <section class="boxModule">
                <section class="boxModuleBackground">
                  <slot name="leftOne">
                    <div class="moduleTitle">楼栋排行榜2</div>
                    <div class="moduleChart"></div>
                  </slot>
                </section>
              </section>
              <section class="boxModule">
                <section class="boxModuleBackground">
                  <slot name="leftTwo">
                    <div class="moduleTitle">楼栋排行榜2</div>
                    <div class="moduleChart"></div>
                  </slot>
                </section>
              </section>
              <section class="boxModule">
                <section class="boxModuleBackground">
                  <slot name="leftThree">
                    <div class="moduleTitle">楼栋排行榜2</div>
                    <div class="moduleChart"></div>
                  </slot>
                </section>
              </section>
            </div>
            <div class="screenMainCenter">
              <total-count
                :countObject="countObject"
                :txt="totalTxtObject"
                :isToken="true"
              />
              <mini-map
                ref="overviewMapRef"
                :handleMoveMapForProject="handleMoveMapForProject"
                :handleMoveMapForArea="handleMoveMapForArea"
                @map-area-change="handleOverViewChangeMapArea"
              />
            </div>
            <div class="screenMainRight">
              <section class="boxModule">
                <section class="boxModuleBackground">
                  <slot name="rightOne">
                    <div class="moduleTitle">楼栋排行榜2</div>
                    <div class="moduleChart"></div>
                  </slot>
                </section>
              </section>
              <section class="boxModule">
                <section class="boxModuleBackground">
                  <slot name="rightTwo">
                    <div class="moduleTitle">楼栋排行榜2</div>
                    <div class="moduleChart"></div>
                  </slot>
                </section>
              </section>
              <section class="boxModule">
                <section class="boxModuleBackground">
                  <slot name="rightThree">
                    <div class="moduleTitle">楼栋排行榜2</div>
                    <div class="moduleChart"></div>
                  </slot>
                </section>
              </section>
            </div>
          </section>
        </section>
        <section class="screenFooter">
          <slot name="footer"></slot>
        </section>
      </template>
      <template v-else>
        <main-map
          ref="mapModeRef"
          class="screenBottomMap"
          :handleMoveMapForProject="handleMoveMapForProject"
          @map-area-change="handleMapAreaChange"
        />
      </template>
    </section>
  </section>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
import { ssoClient } from '@triascloud/sso-client';
import MiniMap from './map.vue';
import TotalCount from '@/views/iot/screen/components/total-count.vue';
import GlobalWebSocket from '@/services/socket.js';
import { flexDpr } from '@/assets/js/flex-dpr.js';
import MainMap from '@/views/iot/screen/components/main-map.vue';
import { screenModule } from '@/enum/store.js';
import { getAddressType } from './final';

/**
 * @typedef QueueMap 地图层级Map
 * @property {string} level 地图的层级
 * @property {string} adcode 地图的地理编码
 * @property {string} value 地图的名称
 * @property {string} title 地图的标题
 */
// import loading from '@/components/loading';

flexDpr();

/** @name 概览模式 */
const PATTERN_DETAIL = 'pattern_detail';
/** @name 地图模式 */
const PATTERN_MAP = 'pattern_map';
/** @name 区域统计信息 */
const SCREEN_TOP_DATA = 'SCREEN_TOP_DATA';
/** @name 推送地图项目数据 */
const SCREEN = 'SCREEN';
/** @name 接收地图项目数据 */
const GET_MAP_PROJECT = 'GET_MAP_PROJECT';
/** @name 关注项目数据 */
const SCREEN_MY_ATTENTION_PROJECT = 'SCREEN_MY_ATTENTION_PROJECT';
/** @name 推荐项目数据 */
const RECOMMEND_PROJECT = 'RECOMMEND_PROJECT';
/** @name 热门城市据 */
const SCREEN_HOT_CITY_RANKING = 'SCREEN_HOT_CITY_RANKING';
/** @name 鼠标移动到地图点时候事件 */
const MOVE_MAP_PROJECT = 'MOVE_MAP_PROJECT';
/** @name 鼠标移动到地图的区域 */
const MOVE_MAP_ADDRESS = 'MOVE_MAP_ADDRESS';
/**@name 统计数刷新 */
// const DEVICE_LIFE_CYCLE_UPDATE = 'deviceLifeCycleUpdate';
// /**@name 数据更新 */
// const SCREEN_DATA_UPDATE = 'SCREEN_DATA_UPDATE';

@Component({
  components: {
    TotalCount,
    MiniMap,
    MainMap,
  },
})
export default class MainScreen extends Vue {
  @screenModule.State mapUrlQueue; // 跳转地址队列
  /** @type QueueMap */
  @screenModule.Getter lastMapUrlQueueItem;

  totalTxtObject = {
    oneTxt: '总楼栋数',
    twoTxt: '待进场楼栋数',
    threeTxt: '已进场楼栋数',
    fourTxt: '本月安全指数',
  };
  PATTERN_DETAIL = PATTERN_DETAIL;
  PATTERN_MAP = PATTERN_MAP;
  pattern = PATTERN_DETAIL;
  async mounted() {
    await this.initWebsocket();
    this.sendAllEvent();
  }
  sendAllEvent() {
    if (this.pattern === this.PATTERN_DETAIL) {
      this.$emit('areaChange');
      this.getOverviewDataByWebsocket();
    } else {
      this.getMapDataByWebsocket();
    }
  }
  socket = null;
  async initWebsocket() {
    const token = await ssoClient.getToken();
    this.socket = new GlobalWebSocket({ token });
    this.socket.webSocketServerListener(this.messageHandle);
  }
  initFirst = false;
  messageHandle(result) {
    // this.$nextTick(() => {
    //   this.loadingMark.close();
    // });
    const { event, data } = result;
    switch (event) {
      case SCREEN_TOP_DATA:
        this.countObject = {
          ...data,
          ...data.screenTopDataDTO,
        };
        break;
      case MOVE_MAP_PROJECT:
        this.$refs.overviewMapRef &&
          this.$refs.overviewMapRef.handleMoveDataByProject(data);
        this.$refs.mapModeRef &&
          this.$refs.mapModeRef.handleMoveDataByProject(data);
        break;
      case MOVE_MAP_ADDRESS:
        this.$refs.overviewMapRef &&
          this.$refs.overviewMapRef.handleMoveDataByArea(data);
        break;
      case SCREEN_MY_ATTENTION_PROJECT:
        this.$store.commit('project/updateAttentionProjects', data);
        break;
      case RECOMMEND_PROJECT:
        this.$store.commit('project/updateRecommendProjects', data);
        break;
      case SCREEN_HOT_CITY_RANKING:
        this.$store.commit('project/updateHotCities', data);
        break;
      case GET_MAP_PROJECT:
        // if (!this.initFirst) {
        this.$store.commit('project/updateProjects', data);
        // this.initFirst = true;
        // }
        this.$refs.overviewMapRef && this.$refs.overviewMapRef.initData(data);
        this.$refs.mapModeRef && this.$refs.mapModeRef.initEvent(data);

        // 在数据更新后，再加载地图，保证数据是最新的
        // this.$on('data-update', () => {
        //   setTimeout(() => {
        //     this.$refs.overviewMapRef &&
        //       this.$refs.overviewMapRef.initLoadMap();
        //   }, 100);
        // });
        break;
      // case DEVICE_LIFE_CYCLE_UPDATE:
      //   // case SCREEN_DATA_UPDATE:
      //   this.$emit('data-update');
      //   this.getOverviewDataByWebsocket(true);
      //   break;
      default:
        this.$emit('socket-send-message', result);
        break;
    }
  }
  beforeDestroy() {
    this.$store.commit('project/updateProjects', []);
    // this.socket.close();
  }
  /** @name 鼠标在地图的点移动获取项目的数据  */
  handleMoveMapForProject(projectId = '') {
    this.socket.webSocketEventSend(
      `triascloud-iot-screen:${MOVE_MAP_PROJECT}`,
      {
        projectId,
      },
    );
  }
  /** @name 鼠标移动到地图的区域，获取区域信息  */
  handleMoveMapForArea(options) {
    if (options.area === options.provincesAndCities) {
      options.area = '';
    }
    this.socket.webSocketEventSend(
      `triascloud-iot-screen:${MOVE_MAP_ADDRESS}`,
      options,
    );
  }
  /** @name 搜索地区，切换地图 */
  handleAddMapUrlQueueLink(item) {
    this.$refs.overviewMapRef &&
      this.$refs.overviewMapRef.addMapUrlQueueLink(item);
  }

  site = '';
  /**
   * @name 概览模式-获取地图散点数据和如下的数据
   * @description 7日隐患事件统计 OR 7日预警统计
   * 楼栋高度排行榜TOP5
   * 楼栋排行榜
   * 顶部统计数据
   * 设备故障排行TOP5
   * 7日安全指数波动图
   * 7日安全防护平台状态统计
   */
  getOverviewDataByWebsocket(updated = false) {
    !updated &&
      this.$refs.overviewMapRef &&
      this.$refs.overviewMapRef.initLoadMap();

    this.socket.webSocketEventSend(
      `triascloud-iot-screen:${SCREEN}`,
      this.getScreenParams(),
    );
  }
  getScreenParams() {
    const object = {
      provincesAndCities: this.site,
    };
    if (this.addressType) {
      object.addressType = this.addressType;
    }

    // 获取缓存的地图层级数据
    if (this.mapUrlQueue.length) {
      if (this.lastMapUrlQueueItem.level !== 'CHINA') {
        object.addressType = getAddressType(this.lastMapUrlQueueItem.level);
      }
      object.provincesAndCities = this.lastMapUrlQueueItem.value;
    }
    return object;
  }
  /**
   * @name 地图模式-获取地图散点数据和如下的数据
   * @description 7日隐患事件统计 OR 7日预警统计
   * 楼栋高度排行榜TOP5
   * 楼栋排行榜
   * 顶部统计数据
   * 设备故障排行TOP5
   * 7日安全指数波动图
   * 7日爬架状态统计
   */
  getMapDataByWebsocket() {
    this.$refs.mapModeRef && this.$refs.mapModeRef.initLoadMap();
    this.socket.webSocketEventSend(`triascloud-iot-screen:${SCREEN}`, {
      provincesAndCities: this.site,
    });
  }

  countObject = {
    buildLoadingCountDifference: 0,
    safeIndexDifference: 0,
    totalBuildingCountDifference: 0,
    waitEnterCountDifference: 0,
    safeIndex: 0,
    totalBuildingCount: 0,
    buildLoadingBuildingCount: 0,
    waitEnterBuildingCount: 0,
  };

  /** @name 用于设备故障排行TOP5和楼栋排行榜  */
  addressType = 'PROVINCE';
  /**
   * @name 概览模式切换省市
   */
  handleOverViewChangeMapArea(options) {
    this.site = options.site;
    this.addressType = options.type;
    this.sendAllEvent();
  }
  /**
   * @name 地图模式切换省市
   * @description 需要切换site
   */
  handleMapAreaChange(site) {
    this.site = site;
    this.sendAllEvent();
  }
}
</script>
<style lang="less" scoped>
@fff: #fff;
@bgColor: #02051b;
@fontColor: rgba(5, 211, 255, 1);
@fontcolor2: rgba(5, 209, 253, 1);
@lineColor: rgba(13, 200, 254, 1);
@lineColor5: rgba(13, 200, 254, 0.5);
.mainScreen {
  background: @bgColor;
  width: 100%;
  height: 100%;
  padding: 0.15rem;
  box-sizing: border-box;
  display: flex;
  position: relative;
  overflow: hidden;
  .screenBox {
    box-shadow: 0 0 0.06rem 0.02rem rgba(12, 181, 232, 0.4) inset;
    height: 100%;
    width: 100%;
    position: relative;
    .screenHeader {
      height: 0.8rem;
    }
    .screenMain {
      height: calc(100% - 1.44rem);
      padding: 0.2rem;
      .screenContent {
        height: 100%;
        width: 100%;
        position: relative;
      }
      .screenMainLeft {
        width: 21.8vw;
        min-width: 2.5rem;
        overflow-y: hidden;
        height: 100%;
        position: absolute;
        z-index: 3;
        display: flex;
        flex-direction: column;
      }
      .screenMainCenter {
        width: 100%;
        position: relative;
        z-index: 1;
        height: 100%;
      }
      .screenMainRight {
        width: 21.8vw;
        min-width: 2.5rem;
        overflow-y: hidden;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 3;
        display: flex;
        flex-direction: column;
      }
    }
    .screenFooter {
      border-top: 0.01rem solid rgba(1, 142, 183, 0.5);
      height: 0.64rem;
      line-height: 0.64rem;
    }
    .screenBottomMap {
      height: calc(100% - 0.84rem);
      top: 0.8rem;
      left: 0.04rem;
      right: 0.04rem;
      bottom: 0.04rem;
    }
    // 模块样式
    .boxModule {
      margin-bottom: 0.3rem;
      width: 100%;
      background-color: @bgColor;

      padding: 0.02rem;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      font-size: 0.16rem;
      position: relative;
      flex: 1;
      min-height: 2rem;
      .boxModuleBackground {
        box-shadow: 0 0 0.18rem 0.02rem rgba(12, 181, 232, 0.4) inset;
        background: linear-gradient(#07d3fd, #07d3fd) left top,
          linear-gradient(#07d3fd, #07d3fd) left top,
          linear-gradient(#07d3fd, #07d3fd) right top,
          linear-gradient(#07d3fd, #07d3fd) right top,
          linear-gradient(#07d3fd, #07d3fd) left bottom,
          linear-gradient(#07d3fd, #07d3fd) left bottom,
          linear-gradient(#07d3fd, #07d3fd) right bottom,
          linear-gradient(#07d3fd, #07d3fd) right bottom;
        background-repeat: no-repeat;
        background-size: 0.02rem 0.2rem, 0.2rem 0.02rem;
        height: 100%;
      }
      .moduleTitle {
        height: 0.4rem;
        width: 100%;
        font-weight: normal;
        color: #05d2fe;
        line-height: 0.4rem;
        background: -webkit-gradient(
          linear,
          left top,
          right top,
          from(rgba(5, 211, 255, 0.2)),
          color-stop(98%, transparent)
        );
        background: linear-gradient(
          90deg,
          rgba(5, 211, 255, 0.2) 0%,
          transparent 98%
        );
        padding: 0 0.2rem;
        box-sizing: border-box;
      }
      .moduleChart {
        height: calc(100% - 0.4rem);
        overflow-x: hidden;
        overflow-y: auto;
        color: #fff;
        width: 100%;
        box-sizing: border-box;
        scrollbar-color: transparent transparent;
        scrollbar-track-color: transparent;
        -ms-scrollbar-track-color: transparent;
        &::-webkit-scrollbar {
          width: 0 !important;
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>
