<template>
  <div class="mainMapWrap">
    <el-amap
      vid="amap"
      :center="center"
      :amap-manager="amapManager"
      :events="mapEvents"
      :scrollWheel="true"
      :zoom="zoom"
      class="bmap"
      ref="bigMap"
    >
    </el-amap>
    <div class="mainMapButton" v-if="type !== LEVEL_COUNTRY">
      <span class="mainMapLabel" @click="handleBack">{{
        $t('screen.btn.back')
      }}</span>
    </div>
    <div class="zoomBox">
      <div class="zoomWrapper">
        <span class="zoomItem" @click="handleClickDown">
          <a-icon type="minus" />
        </span>
        <span class="zoomItem" @click="handleClickUp"
          ><a-icon type="plus"
        /></span>
      </div>
      <ul class="tipBoxWrap">
        <li
          class="tipItem"
          v-for="(item, index) in buttonGroups"
          :key="item.key"
          @click="handleSelectButton(index, item)"
        >
          <span
            class="iconBox"
            :style="[
              item.selected
                ? { backgroundColor: item.color }
                : { backgroundColor: '#929292' },
            ]"
          ></span>
          <span class="labelBox">{{ $t(item.label) }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import VueAMap from 'vue-amap';
// import debounce from 'lodash/debounce';
import throttle from 'lodash/throttle';
import { getAddressInfoByAmap } from '@/services/iot-electric-box/map.js';
import {
  Color_Rising,
  Color_Waiting,
  Color_Building,
  Color_Outing,
  Color_Electricbox,
  LEVEL_WAITING,
  LEVEL_BUILDING,
  LEVEL_OUTING,
  LEVEL_ELECTRIC_BOX,
  LEVEL_RISING,
} from '@/components/main-screen/final.js';

const LEVEL_COUNTRY = 'country';
const LEVEL_PROVINCE = 'province';
const LEVEL_DISTRICT = 'district';
const LEVEL_CITY = 'city';
const CHINA = '中国';
const OVERLAY_COLOR_Fill = '#03263f';
const OVERLAY_COLOR_STROKE = '#1c91a9';

Vue.use(VueAMap);
let amapManager = VueAMap.initAMapApiLoader({
  key: process.env.VUE_APP_AMAP_WEB_API_KEY,
  plugin: [
    'AMap.DistrictSearch',
    'AMap.DistrictLayer',
    'AMap.Geocoder',
    'AMap.Geolocation',
  ],
  v: '1.4.15',
});

const GDPSpeed = {
  520000: 10, // 贵州
  540000: 10, // 西藏
  530000: 8.5, // 云南
  500000: 8.5, // 重庆
  360000: 8.5, // 江西
  340000: 8.0, // 安徽
  510000: 7.5, // 四川
  350000: 8.5, // 福建
  430000: 8.0, // 湖南
  420000: 7.5, // 湖北
  410000: 7.5, // 河南
  330000: 7.0, // 浙江
  640000: 7.5, // 宁夏
  650000: 7.0, // 新疆
  440000: 7.0, // 广东
  370000: 7.0, // 山东
  450000: 7.3, // 广西
  630000: 7.0, // 青海
  320000: 7.0, // 江苏
  140000: 6.5, // 山西
  460000: 7, // 海南
  310000: 6.5, // 上海
  110000: 6.5, // 北京
  130000: 6.5, // 河北
  230000: 6, // 黑龙江
  220000: 6, // 吉林
  210000: 6.5, // 辽宁
  150000: 6.5, // 内蒙古
  120000: 5, // 天津
  620000: 6, // 甘肃
  610000: 8.5, // 甘肃
  710000: 2.64, // 台湾
  810000: 3.0, // 香港
  820000: 4.7, // 澳门
};

export default {
  props: {
    handleMoveMapForProject: {
      type: Function,
      default: function() {},
    },
  },
  data() {
    return {
      zoom: 4,
      center: [116.3683244, 39.915085],
      snapCenter: [],
      amapManager: amapManager,
      mapEvents: {
        init: o => {
          o.setStatus({
            doubleClickZoom: false, // 关闭鼠标双击缩放
          });
          o.setDefaultCursor('default'); // 设置鼠标样式
          o.setMapStyle('amap://styles/darkblue'); // 极夜蓝的样式
          this.$nextTick(() => {
            this.initCountryLayer();
          });
          this.snapCenter[0] = o.getCenter().lng;
          this.snapCenter[1] = o.getCenter().lat;
        },
        dblclick: e => {
          if (e && e.lnglat) {
            const lnglat = [e.lnglat.lng, e.lnglat.lat];
            this.findArea(lnglat);
          }
        },
      },
      type: LEVEL_COUNTRY,
      provinceName: '',
      provinceAdcode: '',
      provinceDepth: 1,
      cityName: '',
      cityAdcode: '',
      districtName: '',
      districtAdcode: '',
      polygons: [],
      province: {
        adcode: ['440000'],
      },
      countryLayer: undefined,
      provinceLayer: undefined,
      locaLoading: false, // loca脚本是否加载完成
      normalMarker: undefined, // 弹层
      currentProject: {}, // 当前鼠标选中的项目
      cacheData: null,
      outingLayer: null,
      electricBoxLayer: null,
      buildingLayer: null,
      waitingLayer: null,
      risingLayer: null,
      buttonGroups: [
        {
          label: 'screen.btn.notStarted',
          color: Color_Waiting,
          key: LEVEL_WAITING,
          selected: true,
        },
        {
          label: 'screen.btn.started',
          color: Color_Building,
          key: LEVEL_BUILDING,
          selected: true,
        },
        {
          label: 'screen.btn.terminalsInstalled',
          color: Color_Electricbox,
          key: LEVEL_ELECTRIC_BOX,
          selected: true,
        },
        {
          label: 'screen.btn.operating',
          color: Color_Rising,
          key: LEVEL_RISING,
          selected: true,
        },
        {
          label: 'screen.btn.finished',
          color: Color_Outing,
          key: LEVEL_OUTING,
          selected: true,
        },
      ],
    };
  },
  created() {
    this.LEVEL_COUNTRY = LEVEL_COUNTRY;
  },
  mounted() {
    // 监听 window.resize 事件
    // TODO 防抖
    window.addEventListener(
      'resize',
      throttle(() => {
        this.changeResize();
      }),
      200,
    );
  },
  methods: {
    handleClickUp() {
      this.zoom = this.zoom + 1;
    },
    handleClickDown() {
      this.zoom = this.zoom - 1;
    },
    removeProjectLayer() {
      this.$refs.bigMap.$$getInstance().remove(this.waitingLayer);
      this.$refs.bigMap.$$getInstance().remove(this.electricBoxLayer);
      this.$refs.bigMap.$$getInstance().remove(this.buildingLayer);
      this.$refs.bigMap.$$getInstance().remove(this.outingLayer);
      this.$refs.bigMap.$$getInstance().remove(this.risingLayer);
    },
    changeResize() {
      this.removeProjectLayer();
      this.initLoadMap();
      setTimeout(() => {
        this.initEvent(this.cacheData);
      }, 100);
    },
    projectToHtml(obj) {
      let str = `<div class="projectBuildToolForWindow">`;
      str += `
          <p class="titleTool">${obj.name}</p>
          <div class="toolTipBuild">
            <span class="labelTip">${this.$t(
              'matterwebset.electricbox.buildingnum',
            )}：</span>
              ${obj.buildQuantity}
          </div>
          <div class="toolTipBuild">
            <span class="labelTip">${this.$t(
              'matterwebset.generalscreen.warningnum',
            )}：</span>
              ${obj.alarmQuantity}
          </div>

        `;
      if (obj.thisMonthSafeIndex) {
        str += `
          <div class="toolTipBuild">
            <span class="labelTip">${this.$t(
              'screen.count.monthlySafeRate',
            )}：</span>
              ${obj.thisMonthSafeIndex}%
          </div>
        `;
      }
      str += `</div>`;
      return str;
    },
    handleMoveDataByProject(data) {
      if (this.normalMarker) {
        this.$refs.bigMap.$$getInstance().remove(this.normalMarker);
      }
      // eslint-disable-next-line no-undef
      this.normalMarker = new AMap.Marker({
        // eslint-disable-next-line no-undef
        offset: new AMap.Pixel(20, -180),
        zIndex: 101,
      });
      this.normalMarker.setContent(this.projectToHtml(data));
      const item = this.currentProject;
      this.normalMarker.setPosition([item.lng, item.lat]);
      this.$nextTick(() => {
        this.$refs.bigMap.$$getInstance().add(this.normalMarker);
      });
    },
    initLoadMap() {
      this.initLocaScript();
    },
    initEvent(data) {
      // this.removeProjectLayer();
      this.cacheData = data;
      this.initLocaData(data);
    },
    initCountryLayer() {
      this.provinceLayer &&
        this.$refs.bigMap.$$getInstance().remove(this.provinceLayer);
      // eslint-disable-next-line no-undef
      const disCountry = new AMap.DistrictLayer.Country({
        SOC: 'CHN',
        depth: 1,
        styles: {
          'nation-stroke': '#22ffff',
          'coastline-stroke': [0.8, 0.63, 0.94, 1],
          'province-stroke': '#1c91a9',
          'city-stroke': '#1c91a9',
          'fill': function() {
            return 'rgba(3, 38, 63, 0.9)';
          },
        },
      });
      this.countryLayer = disCountry;
      disCountry.setMap(this.$refs.bigMap.$$getInstance());
      this.$refs.bigMap.$$getInstance().setMapStyle('amap://styles/darkblue'); // 极夜蓝的样式
      this.$refs.bigMap.$$getInstance().setCity(CHINA);
    },
    initProvinceLayer() {
      this.countryLayer &&
        this.$refs.bigMap.$$getInstance().remove(this.countryLayer);
      this.provinceLayer &&
        this.$refs.bigMap.$$getInstance().remove(this.provinceLayer);
      // eslint-disable-next-line no-undef
      const disProvince = new AMap.DistrictLayer.Province({
        zIndex: 12,
        adcode: this.province.adcode,
        depth: this.provinceDepth,
        styles: {
          'fill': function() {
            return 'rgba(3, 38, 63, 0.9)';
          },
          'province-stroke': '#1c91a9',
          'city-stroke': '#1c91a9', // 中国地级市边界
          'county-stroke': '#1c91a9', // 中国区县边界
        },
      });
      this.provinceLayer = disProvince;
      disProvince.setMap(this.$refs.bigMap.$$getInstance());
    },
    findArea(lnglat) {
      // eslint-disable-next-line no-undef
      const geocoder = new AMap.Geocoder({
        city: '010',
        radius: 1000,
      });

      geocoder.getAddress(lnglat, (status, result) => {
        if (status === 'complete' && result.regeocode) {
          const obj = result.regeocode;
          this.drawArea(obj);
        }
      });
    },
    // 获取绘制的地区的位置
    drawArea(obj) {
      const { province, city, district } = obj.addressComponent;
      if (this.provinceName !== province && province) {
        this.drawProvinceArea(obj);
        return false;
      }

      if (this.provinceName === province && city && this.cityName !== city) {
        this.drawCityArea(city);
        return false;
      }

      // 香港为省级单位，没有city这个值
      if (
        this.provinceName === province &&
        this.cityName === city &&
        city &&
        district &&
        this.districtName !== district
      ) {
        this.drawDistrictArea(district);
        return false;
      }
    },
    drawProvinceArea(obj) {
      this.provinceName = obj.addressComponent.province;
      let adcodeSlice = obj.addressComponent.adcode.slice(0, 2);
      let hasAdCode = false;
      let adcode;
      Object.keys(GDPSpeed).forEach(v => {
        if (v.slice(0, 2) === adcodeSlice) {
          hasAdCode = true;
          adcode = [v];
        }
      });
      if (hasAdCode) {
        this.type = LEVEL_PROVINCE;
        this.province.adcode = adcode;
        this.provinceAdcode = adcode;
        this.provinceDepth = 1;
        this.handleEmitAreaChange(this.provinceName, this.type);
        this.initProvinceLayer();
        this.handleDrawArea(this.provinceName, this.type, 0, true);
      }
    },
    drawCityArea(city) {
      this.cityName = city;
      this.provinceLayer &&
        this.$refs.bigMap.$$getInstance().remove(this.provinceLayer);
      this.type = LEVEL_CITY;
      this.handleEmitAreaChange(this.cityName, this.type);
      this.fetchDistrictByCityOrDistrict(city, adcode => {
        this.provinceDepth = 2;
        this.cityAdcode = adcode;
        this.province.adcode = [adcode];
        this.initProvinceLayer();
        this.handleDrawArea(this.cityName, this.type, 0, true);
      });
    },
    drawDistrictArea(district) {
      this.districtName = district;
      this.provinceLayer &&
        this.$refs.bigMap.$$getInstance().remove(this.provinceLayer);
      this.type = LEVEL_DISTRICT;
      this.fetchDistrictByCityOrDistrict(district, adcode => {
        this.provinceDepth = 2;
        this.districtAdcode = adcode;
        this.province.adcode = [adcode];
        this.initProvinceLayer();
        this.handleDrawArea(this.districtName, this.type, 0, true);
      });
    },
    handleDrawArea(keywords, level, fillOpacity = 0.7, clear = false) {
      if (!keywords) return;
      // eslint-disable-next-line no-undef
      const districtSearch = new AMap.DistrictSearch({
        level, // 关键字对应的行政区级别，country表示国家
        extensions: 'all', // 返回行政区边界坐标组等具体信息
        subdistrict: 1, // 显示下级行政区级数，1表示返回下一级行政区
      });

      // 搜索所有省/直辖市信息
      districtSearch.search(keywords, (status, result) => {
        // 查询成功时，result即为对应的行政区信息
        if (
          result.districtList &&
          result.districtList[0] &&
          result.districtList[0].boundaries
        ) {
          this.polygons.length > 0 &&
            this.$refs.bigMap.$$getInstance().remove(this.polygons); // 清除上次结果
          const polygons = [];
          const bounds = result.districtList[0].boundaries;
          if (bounds) {
            for (let current of bounds) {
              // 生成行政区划polygon
              // eslint-disable-next-line no-undef
              let polygon = new AMap.Polygon({
                path: current,
              });
              polygons.push(polygon);
            }
          }
          this.polygons = polygons;
          // eslint-disable-next-line no-undef
          const overlayGroup = new AMap.OverlayGroup(polygons);

          overlayGroup.setOptions({
            fillOpacity: fillOpacity,
            fillColor: OVERLAY_COLOR_Fill,
            strokeColor: OVERLAY_COLOR_STROKE,
            strokeWeight: 1,
          });
          this.$refs.bigMap.$$getInstance().add(overlayGroup);
          this.$refs.bigMap.$$getInstance().setFitView(polygons); // 视口自适应

          if (clear) {
            this.$refs.bigMap.$$getInstance().remove(this.polygons); // 清除上次结果
          }
        }
      });
    },
    async fetchDistrict(keywords) {
      const result = await getAddressInfoByAmap({
        keywords,
      });
      const object = JSON.parse(result);
      if (object.districts) {
        const obj = object.districts[0];
        const [lng, lat] = obj.center.split(',');
        this.$refs.bigMap.$$getInstance().setCenter([lng, lat]);
      }
    },
    async fetchDistrictByCityOrDistrict(keywords, callback) {
      const result = await getAddressInfoByAmap({
        keywords,
      });
      const object = JSON.parse(result);
      if (object.districts) {
        const obj = object.districts[0];
        callback(obj.adcode);
      }
    },
    handleBack() {
      let siteName = '';
      if (this.type === LEVEL_PROVINCE) {
        this.type = LEVEL_COUNTRY;
        this.initCountryLayer();
        this.fetchDistrict(CHINA);
        siteName = '';
      } else if (this.type === LEVEL_CITY) {
        this.type = LEVEL_PROVINCE;
        this.provinceDepth = 1;
        this.province.adcode = [this.provinceAdcode];
        this.initProvinceLayer();
        this.handleDrawArea(this.provinceName, this.type, 0, true);
        siteName = this.provinceName;
      } else if (this.type === LEVEL_DISTRICT) {
        this.type = LEVEL_CITY;
        this.provinceDepth = 2;
        this.province.adcode = [this.cityAdcode];
        this.initProvinceLayer();
        this.handleDrawArea(this.cityName, this.type, 0, true);
        siteName = this.cityName;
        // eslint-disable-next-line no-empty
      } else {
      }
      this.handleEmitAreaChange(siteName, this.type);
    },
    handleEmitAreaChange(siteName, type) {
      this.removeProjectLayer();
      this.$emit('map-area-change', siteName, this.formatAreaType(type));
    },
    formatAreaType(type) {
      const labelType = {};
      labelType[`${LEVEL_COUNTRY}`] = '1';
      labelType[`${LEVEL_PROVINCE}`] = '2';
      labelType[`${LEVEL_CITY}`] = '3';
      labelType[`${LEVEL_DISTRICT}`] = '3';
      return labelType[type];
    },
    initLocaScript(cb) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = `https://webapi.amap.com/loca?v=1.3.2&key=${process.env.VUE_APP_AMAP_WEB_API_KEY}`;
      script.addEventListener('load', () => {
        this.locaLoading = true;
        cb && cb();
      });
      document.body.appendChild(script);
    },
    initLocaData(array) {
      const arrayWaiting = []; // 待进场项目
      const arrayBuilding = []; // 已进场项目
      const arrayOuting = []; // 已进场项目
      const arrayRising = []; // 已进场项目
      const arrayElectricBox = []; // 已进场有安监终端项目

      array.forEach(v => {
        if (v.process === LEVEL_WAITING) {
          arrayWaiting.push(v);
        }

        if (v.process === LEVEL_BUILDING) {
          arrayBuilding.push(v);
        }

        if (v.process === LEVEL_OUTING) {
          arrayOuting.push(v);
        }
        if (v.process === LEVEL_RISING) {
          arrayRising.push(v);
        }
        if (v.process === LEVEL_ELECTRIC_BOX) {
          arrayElectricBox.push(v);
        }
      });

      this.waitingLayer = this.initLocaLayer(arrayWaiting, {
        style: {
          radius: this.$size(5),
          color: Color_Waiting,
          opacity: 0.8,
        },
        selectStyle: {
          radius: this.$size(8),
          color: Color_Waiting,
        },
        zIndex: 102,
      });
      this.buildingLayer = this.initLocaLayer(arrayBuilding, {
        style: {
          radius: this.$size(5),
          color: Color_Building,
          opacity: 0.8,
        },
        selectStyle: {
          radius: this.$size(8),
          color: Color_Building,
        },
        zIndex: 104,
      });
      this.risingLayer = this.initLocaLayer(arrayRising, {
        style: {
          radius: this.$size(10),
          color: Color_Rising,
          opacity: 0.8,
        },
        selectStyle: {
          radius: this.$size(13),
          color: Color_Rising,
        },
        zIndex: 105,
      });
      this.electricBoxLayer = this.initLocaLayer(arrayElectricBox, {
        style: {
          radius: this.$size(5),
          color: Color_Electricbox,
          opacity: 0.8,
        },
        selectStyle: {
          radius: this.$size(8),
          color: Color_Electricbox,
        },
        zIndex: 106,
      });
      this.outingLayer = this.initLocaLayer(arrayOuting, {
        style: {
          radius: this.$size(5),
          color: Color_Outing,
          opacity: 0.8,
        },
        selectStyle: {
          radius: this.$size(8),
          color: Color_Outing,
        },
        zIndex: 103,
      });
    },
    initLocaLayer(array, styleOptions) {
      // eslint-disable-next-line no-undef
      const layer = new Loca.PointLayer({
        eventSupport: true,
        zIndex: styleOptions && styleOptions.zIndex ? styleOptions.zIndex : 101,
      });

      layer.setMap(this.$refs.bigMap.$$getInstance());
      layer.setData(array, {
        lnglat: function(data) {
          var item = data.value;
          return [item.lng, item.lat];
        },
      });

      const options = Object.assign(
        {},
        {
          style: {
            radius: this.$size(5),
            color: '#FF9900',
            opacity: 0.8,
          },
          selectStyle: {
            radius: this.$size(8),
            color: '#FF9900',
          },
        },
        styleOptions,
      );

      layer.setOptions(options);
      // const width = this.$size();
      // eslint-disable-next-line no-undef
      // const normalMarker = new AMap.Marker({
      //   // eslint-disable-next-line no-undef
      //   offset: new AMap.Pixel(20, -180),
      //   zIndex: 101,
      // });

      // this.normalMarker = normalMarker;

      layer.on(
        'mouseover',
        throttle(e => {
          // if ()
          this.currentProject = e.rawData;
          this.handleMoveMapForProject(e.rawData.id);
        }, 200),
      );

      layer.on(
        'mouseout',
        throttle(() => {
          // eslint-disable-next-line no-undef
          this.$refs.bigMap.$$getInstance().remove(this.normalMarker);
        }, 200),
      );

      layer.on('click', e => {
        if (e.rawData.id) {
          const path =
            this.$route.path === '/iot/screen'
              ? 'projectBuilding'
              : 'buildingFloor';
          this.$router.push({
            path: `/iot/screen/${path}/${e.rawData.id}`,
          });
        }
      });
      layer.render();
      return layer;
    },
  },
};
</script>
<style lang="less" scoped>
.mainMapWrap {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  ::v-deep .amap-container {
    background-color: transparent !important;
  }
  .mainMapButton {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0.1rem;
    .mainMapLabel {
      display: inline-block;
      line-height: 1;
      white-space: nowrap;
      cursor: pointer;
      background: #fff;
      border: 0.01rem solid #eee;
      color: #ffffff;
      text-align: center;
      box-sizing: border-box;
      transition: 0.1s;
      font-weight: 500;
      padding: 0.12rem 0.2rem;
      font-size: 0.14rem;
      border-radius: 0.04rem;
      background-color: rgba(13, 200, 254, 0.5);
      border-color: rgba(13, 200, 254, 0.5);
    }
  }
  .zoomBox {
    position: absolute;
    bottom: 0;
    right: 0.1rem;
    .zoomWrapper {
      background-color: rgba(0, 0, 0, 0.3);
      border-radius: 0.4rem;
      display: flex;
      .zoomItem {
        flex: 1;
        padding: 0.1rem 0;
        display: inline-block;
        cursor: pointer;
        text-align: center;
      }
      margin-bottom: 0.2rem;
    }
    .tipBoxWrap {
      border-radius: 0.1rem;
      padding: 0.1rem;
      background-color: rgba(0, 0, 0, 0.3);
      .tipItem {
        display: block;
        list-style: none;
        text-align: left;
        margin-bottom: 0.15rem;
        cursor: pointer;
        &:last-of-type {
          margin-bottom: 0;
        }
        .iconBox {
          display: inline-block;
          width: 0.14rem;
          height: 0.14rem;
          border-radius: 100%;
          vertical-align: middle;
          margin-right: 0.05rem;
        }
        .labelBox {
          font-size: 0.14rem;
          color: #ffffff;
          display: inline-block;
          vertical-align: middle;
        }
      }
    }
  }
}
</style>
<style lang="less">
.amap-logo {
  visibility: hidden;
}
.projectBuildToolForWindow {
  border: 0.01rem solid rgba(13, 200, 254, 0.6);
  padding: 0.2rem;
  background: rgba(3, 29, 63, 0.6);
  border-radius: 0.03rem;
  box-sizing: border-box;
  white-space: initial;
  width: 2rem;
  box-shadow: 0 0.02rem 0.06rem 0 rgba(114, 124, 245, 0.5);
  .titleTool {
    color: rgba(13, 200, 254, 1);
    font-size: 0.18rem;
    font-weight: bolder;
    line-height: 0.24rem;
    padding-bottom: 0.16rem;
  }
  .TC,
  .iconfont {
    color: rgba(5, 200, 244, 1);
    font-size: 0.16rem;
  }
  .toolTipBuild {
    font-size: 0.14rem;
    padding-bottom: 0.04rem;
    color: #ffffff;
    .labelTip {
      width: 1rem;
      font-size: 0.14rem;
      line-height: 0.24rem;
      display: inline-block;
      color: rgba(255, 255, 255, 0.8);
    }
  }
}
</style>
