<template>
  <div :class="$style.box">
    <div :class="$style.dataBox" v-if="list && list.length">
      <ul-list
        text="text"
        count="time"
        textHtmlFor
        :action="false"
        :list="list"
        :options="{ iterations: 'infinite', name: 'todayModalMove' }"
        cellClickListener
        @cell-click="linkProject"
      />
    </div>
    <div :class="$style.txtBox" v-if="list && list.length">
      今日累计提升：{{ count }}栋
    </div>
    <div v-else :class="$style.noDataBox">
      <no-data-chart />
      <p :class="$style.desc">今日暂无提升楼栋</p>
    </div>
  </div>
</template>
<script>
import { Component, Vue, Prop } from 'vue-property-decorator';
import UlList from '../ul-list';
@Component({
  components: {
    UlList,
  },
})
export default class Today extends Vue {
  @Prop({ type: Array, default: () => [] }) list;
  @Prop({ type: Number, default: 0 }) count;
  @Prop({ type: Function, default: function() {} }) linkProject;
}
</script>
<style lang="less" module>
.box {
  width: 100%;
  overflow: hidden;
  padding: 0 0.2rem;

  .dataBox {
    width: 100%;
    height: 2rem;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .txtBox {
    text-align: left;
    padding-top: 0.1rem;
    font-size: 0.16rem;
    font-weight: 700;
  }

  .noDataBox {
    height: 2.5rem;
    & > div {
      height: 1.8rem;
    }
    .desc {
      text-align: center;
      font-size: 0.16rem;
      color: #fff;
    }
  }
}
</style>
